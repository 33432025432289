export interface EntityBase {
  active: boolean;
  create_at: Date;
  created_at?: Date;
  updated_at: Date;
}

export interface BaseApiParams {
  url: string;
  unauthorizedCallback?: any;
  showSuccesMessage?: boolean;
}

export interface GetParams extends BaseApiParams {}

export interface DeleteParams extends BaseApiParams {
  body?: any;
}

export interface PostParams extends BaseApiParams {
  body: any;
  token?: any;
}

export interface PutParams extends BaseApiParams {
  body: any;
}

export interface PostDownloadParams extends PostParams {
  fileName: string;
  extension: string;
}

export interface ReprocessData {
  method: "POST" | "PATCH" | "GET" | "DELETE";
  body: any;
  url: string;
}

export enum roles {
  OWNER = "owner",
  ADMIN = "admin",
  EDITOR = "editor",
  READ_ONLY = "readonly",
  ORGANIZATION_ADMIN = "organization.admin",
  ORGANIZATION_COMMERCIAL = "organization.comercial",
  ORGANIZATION_TECHNICAL = "organization.technical",
  ORGANIZATION_ZONE = "organization.zone",
  ORGANIZATION_SUBZONE = "organization.subzone",
  ORGANIZATION_COUNTRYSIDE = "organization.countryside",
  ORGANIZATION_GENERAL = "organization.general",
  REPORT_RECEIBABLES = "report.receibables",
}

export enum ConfirmDialogTypeEnum {
  Info = "Info",
  Exito = "Exito",
  Advertencia = "Advertencia",
  Eliminar = "Eliminar",
  Error = "Error",
}

export type AuthenticatedRouteProps = {
  path: string;
  children: any;
  login?: boolean;
  withHeader?: boolean;
  dashboard?: boolean;
  withLogo?: boolean;
  loading?: boolean;
  withDashboardLogo?: boolean;
  headerNavigationName?: string;
  goBack?: () => void;
  withPadding?: boolean;
  showToast?: boolean;
  errorType?: boolean;
  errorMessage?: string | undefined;
  setShowToast?: any;
  setErrorType?: any;
  setErrorMessage?: any;
};

export interface UserVerifyAccountRequest {
  type: "signup" | "recovery";
  email: string | undefined;
  token: string | undefined;
}

export interface ReduxBase {
  loading: boolean;
  error: {
    message: string;
  };
}

export interface ReduxUserData extends ReduxBase {
  user: any;
  userProfile: any;
  userReset: any;
}

export interface ReduxOrgData extends ReduxBase {
  currentOrganization: OrganizationProps;
}

export interface ReduxDashboardData extends ReduxBase {
  currentDashboardOrg: DashboardStatsProps;
}

export interface ReduxCountrysidesMapData extends ReduxBase {
  countrysides: Countryside[];
  countryside: CurrentCountryside[];
  lanzas: DevicesData[];
  infoWindow: InfoWindow[];
}

export interface ReduxMetricsMapData extends ReduxBase {
  metrics: Alert[];
  metricsDefaultConfig: MetricsConfig[];
  metricsConfig: MetricsConfig[];
  metricsTypes: MetricAlertTypes[];
  display: boolean;
  metricsSpeciesTypes: any[];
  metricsZones: any[];
  metricActionsTypes: MetricActionsTypeData[];
  measurements: Measurement[];
}

export interface ReduxSiloBagData extends ReduxBase {
  availableDevices: any;
  countrysidesList: any;
  deviceToAttach: any;
  deviceActionType: any;
  harvests: any;
  lots: any;
  products: any;
  species: any;
  silobagList: any;
  filteredSiloBags: any;
}

export interface ReduxAdministratorData extends ReduxBase {
  provinces: any[];
  localities: ILocalities[];
  cities: IMunicipalities[];
  rolesList: any[];
  countrysidesList: any[];
  usersAdminList: any[];
  usersList: any[];
  companiesList: any[];
  fullCompaniesList: any[];
  lotsList: any[];
  deviceList: any[];
  allowedEstablishments: any[];
  permissions: any[];
}

export interface ReduxNotificationData extends ReduxBase {
  userToNotify: any[];
}

export interface UserDataToNotify {
  users: UserDataNotification[];
  alert_notifications: AlertNotificationData[];
}

export interface UserDataNotification extends EntityBase {
  id: number;
  user_id: number;
  organization_id: number;
  email: boolean;
  sms: boolean;
  whatsapp: boolean;
  telegram: boolean;
  security: boolean;
  quality: boolean;
  qualityIndex: number;
  qualityAlert: QualityAlert[];
  quality_intermediate: boolean;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  users: any;
  notificationMethod: NotificationMediaAlert[];
  setNotificationMethod: any;
  setQualityAlert: any;
}

export interface AlertNotificationData {
  id: number;
  silobag_species_type_id: number;
  organization_id: number;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  silobag_species_types: SilobagSpeciesTypes;
}

export type ReduxType = {
  dashboardData: ReduxDashboardData;
  orgData: ReduxOrgData;
  userData: ReduxUserData;
  countrysidesMapData: ReduxCountrysidesMapData;
  metricsData: ReduxMetricsMapData;
  siloBagData: ReduxSiloBagData;
  administratorData: ReduxAdministratorData;
  notificationData: ReduxNotificationData;
};

export interface IMunicipalities {
  country_l0_id: number;
  country_l0_name: string;
  country_l1_id: number;
  country_l1_name: string;
  created_at: string;
  division_type: string;
  georef_id: number;
  id: number;
  name: string;
  updated_at?: string;
}

export interface ILocalities {
  country_l0_id: number;
  country_l0_name: string;
  country_l1_id: number;
  country_l1_name: string;
  country_l2_id: number;
  country_l2_name: string;
  created_at: string;
  division_type: string;
  georef_id: number;
  id: number;
  municipality_id: number;
  municipality_name: string;
  name: string;
  updated_at?: string;
}

export interface NavBarProps {
  path: string;
  mobile: boolean;
}

export interface HeaderProps {
  width: number;
  withLogo?: boolean;
  withDashboardLogo?: boolean;
  dashboard?: boolean;
  login?: boolean;
  collapsed: boolean;
  headerNavigationName?: string;
  toggleCollapsed: () => void;
  goBack?: () => void;
}

export interface UserLoginRequest {
  username: string | undefined;
  password: string | undefined;
}

export interface ToastProps {
  bodyContent: string | undefined;
  showToast: boolean;
  title: string;
  errorType: boolean;
  toggleShow: () => void;
}

export interface ApiRequest {
  mod: string;
  fun: string;
  args: any;
  config: any;
  sequence: number;
  token?: string;
}

export interface ApiResponse {
  error: boolean;
  code: number;
  message: string;
  data: any;
  info: {
    time: number;
    sequence: number;
  };
}

export interface DashboardProps {
  total_silobags_weight: any[];
  total_silobags_weight_by_product_type: any[];
  total_silobags_weight_by_species_type: any[];
  total_silobags_weight_by_countryside: any[];
  count_silobags_per_lot: any[];
  count_silobags: any[];
  count_alerts: any[];
  silobags_max_alerts: any[];
  total_devices_by_type: any[];
}

export interface OrganizationProps {
  id: number;
  user_id: number;
  legal_name: string;
}

export interface DashboardStatsProps {
  countrysides_data: DashboardEstablishmentStatsProps[];
  device_data: DashboardDeviceStatsProps[];
  silo_data: DashboardSiloStatsProps[];
  siloCount: number;
  tonsCount: {
    total_silobags_calculated_weight: number;
    total_silobags_input_weight: number;
  };
  alertsCount: number;
  monitored_tons_crop_data: MonitoredCrop;
  monitored_tons_establishment_data: MonitoredTonsEstablishment[];
}

export interface DashboardEstablishmentStatsProps {
  lot_id: number;
  count_silobags: number;
  lot_name: string;
}

export interface DashboardDeviceStatsProps {
  dispositivo_estado: { id: number; nombre: string };
  dispositivo_tipo: { id: number; nombre: string };
  id: number;
  identificador: string;
  nombre: string;
  numero: string;
  orden: number;
  version: string;
}

export interface DashboardSiloStatsProps {
  silobag_id: number;
  silobag_name: string;
  silobag_product_type: string;
  silobag_species_type: string;
  count_devices: number;
  countryside_name: string;
  lot_name: string;
  short_name: string;
  measurement_value: number;
  metric_id: number;
  color_name: string;
  color_hex: string;
  silobag_calculated_weight: number;
  silobag_input_weight: number;
}
export interface Countryside {
  organization_id: number;
  organization_name: string;
  company_id: number;
  company_name: string;
  countryside_id: number;
  countryside_name: string;
  countryside_lat: string;
  countryside_lon: string;
  count_silobags: number;
  first_silobag_lat: string;
  first_silobag_lon: string;
}

export interface EstablishmentMapModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  values: {
    geom: any[];
    record: any;
  };
}

export interface EstablishmentPerimeter {
  ELEMENTO_ID: number;
  ELEMENTO_NOMBRE: string;
  ELEMENTO_PRODUCTO: string;
  ELEMENTO_TIPO: string;
  ELEMENTO_COSECHA: string;
  ELEMENTO_CANTIDAD_DISPOSITIVO: number;
  LOTE_ID: number;
  LOTE_NOMBRE: string;
  LOTE_PERIMETRO: string;
  CAMPO_NOMBRE: string;
  ALERTA_NUMERO: number;
  ALERTA_COLOR: string;
  ALERTAS_CANTIDAD: number;
}

export interface MapDashboardProps {
  marker: CurrentCountryside[];
  markersLength: number;
  selectedEstablishment: number;
  setSelectedEstablishment: (value: number) => void;
  setIsAlertsOpen: (value: boolean) => void;
  alertsCounter: number;
  isAlertsOpen: boolean;
  lanzas: any[];
  currentEstablishments: any[];
  map?: google.maps.Map;
  isDashboardOpen?: boolean;
}

export interface CurrentCountryside {
  silobag_id: number;
  silobag_name: string;
  silobag_product_type: string;
  silobag_species_type: string;
  silobag_harvest: string;
  silobag_lat: any;
  silobag_lon: any;
  lot_id: number;
  lot_name: string;
  lot_geom: null;
  countryside_name: string;
  countryside_id: number;
  count_devices: number;
  highest_metric_id: number;
  color_id: number;
  color_name: string;
  color_hex: string;
  count_alerts: number;
}

export interface DevicesData {
  silobag_id: number;
  device_id: number;
  device_name: string;
  device_type: string;
  device_status: string;
  highest_metric_id: number;
  last_alert_created_at: string;
  count_alerts: number;
  color_id: number;
  color_name: string;
  color_hex: string;
}

export interface Devices {
  id: number;
  name: string;
  version: string;
  eui_id: string;
  lance_number: string;
  actility_id: string;
  device_type_id: number;
  device_net_type_id: number;
  device_status_id: number;
  organization_id: number;
  active: boolean;
  created_at: Date;
  updated_at: Date | null;
  orden: number;
  measurements: Measurement[];
}

export interface Measurement {
  id: number;
  eui_id: string;
  firmware_version: string;
  has_metrics: boolean;
  co2_ppm: number;
  co2_percent: number;
  humidity_percent: number;
  temperature_celsius: number;
  battery_voltage: number;
  battery_percent: number;
  has_accelerometer_report: boolean;
  accelerometer_x: number;
  accelerometer_y: number;
  accelerometer_z: number;
  accelerometer_info: string;
  has_errors: boolean;
  error_co2: number;
  error_accelerometer: number;
  error_sht21: number;
  error_eeprom: number;
  has_alarms: boolean;
  alarm_highg: number;
  alarm_orientation_change: number;
  created_at: Date;
}

export interface InfoWindow {
  color_hex: string;
  color_id: number;
  color_name: string;
  countryside_id: number;
  created_at: string;
  device_id: number;
  device_name: string;
  device_status: string;
  device_type: string;
  latest_alert_id: number;
  latest_metric_id: number;
  silobag_id: number;
  silobag_name: string;
  silobag_size: number;
  silobag_input_weight: number;
}

export interface InfoWindowTooltipProps {
  marker: CurrentCountryside;
  alertsCounter: number;
  lanzas: any[];
  mapInfo?: InfoWindow;
}

export interface MonitoredCrop {
  products: MonitoredProductTonsCrop[];
  species: MonitoredSpeciesTonsCrop[];
}
export interface MonitoredProductTonsCrop {
  silobag_product_type: string;
  total_silobags_calculated_weight: number;
  total_silobags_input_weight: number;
}

export interface MonitoredSpeciesTonsCrop {
  silobag_species_type: string;
  total_silobags_calculated_weight: number;
  total_silobags_input_weight: number;
}

export interface MonitoredTonsEstablishment {
  countryside_name: string;
  total_silobags_calculated_weight: number;
  total_silobags_input_weight: number;
}

export interface ModalFormProps {
  editionMode: boolean;
  createMode: boolean;
  onValueChange: (e: any, replaceWholeValue?: boolean) => void;
  onSubmit: any;
  formValues: any;
  valuesToEditOrView?: any;
}

export interface Alert extends EntityBase {
  id: number;
  alert_id: number;
  measurement_id: number;
  device_id: number;
  devices: Devices;
  organization_id: number;
  silobag_id: number;
  silobags: Silobag;
  lot_id: number;
  countryside_id: number;
  countrysides: Countryside;
  company_id: number;
  metrics_alerts_config_id: number;
  metric_id: number;
  // metrics: Metrics;
  metric_alert_type_id: number;
  color_id: number;
  color_hex: string;
  colors: any;
  metric_zone_id: number;
  silobag_species_type_id: number;
  sensitivity_level: number;
  metrics_alerts_config_updated_at: Date;
  measurement_value: number;
  measurement_created_at: number;
  alert_status_id: number;
  alert_type_id: number;
  short_name?: string;
  metrics: any;
  alert_actions: any;
  battery_percent?: any;
}

export interface Metrics extends EntityBase {
  id: number;
  name: string;
  short_name: string;
  min_possible_value: number;
  max_possible_value: number;
  metrics_alerts_config: MetricsAlertsConfig[];
}

export interface Silobag extends EntityBase {}

export interface AlertActions extends EntityBase {
  alert_id: number;
  alert: Alert;
  user_id: number;
  alert_actions_type_id: number;
  alert_actions_types: {
    id: number;
    name: string;
    closes_case: boolean;
  };
  users: {
    fullname: string;
  };
  alert_action_type: AlertActionsTypes;
  action_date: Date;
  days_until_next_action: number;
  next_action_date: Date;
  description: string;
}

export interface AlertActionsTypes extends EntityBase {
  name: string;
  closes_case: boolean;
}

export interface ArrowButtonProps {
  onClick: () => void;
  disabled: boolean;
  className?: string;
}

interface InputProps {
  className?: string;
  disabled?: boolean;
  required?: boolean;
  errorMessage: string;
  formName: string;
  placeholder: string;
  value?: string;
  onChange: (name: string, value: any) => void;
}

export interface NotificationMedia {
  id: number;
  name: string;
  active: boolean;
}

export interface NotificationTrigger extends NotificationMedia {
  color: "orange" | "red" | "green" | "yellow" | string | null;
}

export interface IBadge {
  text: string;
  color: "orange" | "red" | "green" | "yellow" | string | null;
}

export interface NotificationProps {
  role: { role_name: string };
  username: string;
  mail: string;
  notification_trigger: NotificationTrigger[];
  notification_media: NotificationMedia[];
}

export interface InputTextAreaProps extends InputProps {
  rows: number;
}

export interface InputNumberProps extends InputProps {
  step: string;
}

export interface InputSelectProps extends InputProps {
  options: any[];
}

export interface InputTextProps extends InputProps {}

export interface SiloBagFormValues {
  ID: string;
}

export interface SiloBagViewConstBaseProps {
  colSize?: number;
  title?: any;
  value?: any;
}

export interface SiloBagViewConstProps extends SiloBagViewConstBaseProps {
  withCrop?: boolean;
  subData?: SiloBagViewConstBaseProps[];
}

export interface SiloBagDeviceProps {
  silobagId: number;
  devices: {
    id: number;
    lat: null;
    lon: null;
    image: null;
    devices: Devices[];
    silobags_devices_actions: any[];
  }[];
}

export interface TableColumns<T = any> {
  id: string;
  label: string;
  width?: number | string;
  render?: (value: any, record: T) => JSX.Element;
  sortable?: boolean;
}

// COLUMNS TYPES
export interface IColumns {
  acciones?: any;
}
export interface ICompaniesColumns extends IColumns {
  name: string;
  tax_id: string;
  country_l1_name: string;
}
export interface IEstablishmentsColumns extends IColumns {
  name: string;
  country_l2_id: number;
  company_id: number;
  countries_l2: { country_l1_name?: string };
}
export interface IUsersColumns extends IColumns {
  username: string;
  fullname: string;
  email: string;
  phone: string;
  national_id: string;
  roles?: { name: string };
}

// ALERTS CONFIG
export interface MetricAlertTypes {
  id: number;
  name: string;
  active: boolean;
  created_at: Date;
  updated_at?: any;
}

export interface Colors {
  id: number;
  name: string;
  hex: string;
  active: boolean;
  created_at: Date;
  updated_at?: any;
}

export interface SilobagSpeciesTypes {
  id: number;
  name: string;
  active: boolean;
  created_at: Date;
  updated_at?: any;
}

export interface MetricZones {
  id: number;
  name: string;
  active: boolean;
  created_at: Date;
  updated_at?: any;
}

export interface MetricsAlertsConfig {
  id: number;
  metric_id: number;
  metric_alert_type_id: number;
  color_id: number;
  metric_zone_id: number;
  silobag_species_type_id: number;
  sensitivity_level: number;
  min_value: number;
  max_value: number;
  organization_id: number;
  active: boolean;
  created_at: Date;
  updated_at?: any;
  metric_alert_types: MetricAlertTypes;
  colors: Colors;
  silobag_species_types: SilobagSpeciesTypes;
  metric_zones: MetricZones;
}

export interface MetricsConfig {
  id: number;
  name: string;
  short_name: string;
  min_possible_value: number;
  max_possible_value: number;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  metrics_alerts_config: MetricsAlertsConfig[];
}

export interface QualityAlert {
  userId: number;
  alertQualityNotificationData: QualityNotificationAlert[];
}

export interface QualityNotificationAlert {
  id: number;
  name: string;
  color: string;
  active: boolean;
}

export interface NotificationMediaAlert {
  userId: number;
  alertNotificationMediaData: NotificationMediaData[];
}

export interface NotificationMediaData {
  id: number;
  name: string;
  color: string;
  active: boolean;
  style: any;
  icon: any;
}

export interface MetricActionsTypeData {
  id: number;
  name: string;
  closes_case: boolean;
}
