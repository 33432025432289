import { Endpoints } from "../../config/endpoints";
import {
  API_URL_AUTH,
  API_URL_IOF,
  API_WOZALABS_URL,
  LAST_ALERT,
  LOCAL_STORAGE_JWT,
  LOCAL_STORAGE_PROFILE,
  LOCAL_STORAGE_REFRESH_JWT,
} from "../../config/general-config";
import { apiGet, apiPost } from "../../shared/ApiService";
import { asyncLocalStorage } from "../../shared/helper";

import * as _ from "lodash";
import { ApiResponseAuth } from "./Type";
import { UserLoginRequest, UserVerifyAccountRequest } from "../../shared/types";

const loginWithUsernameAndPassword = async (userLogin: UserLoginRequest) => {
  const responseLogin: ApiResponseAuth = await genericRequest(
    Endpoints.API_IOF.AUTH,
    userLogin
  );

  const access_token = responseLogin.token;
  const refresh_token = responseLogin.token;

  const profileData: any = responseLogin.user;

  await saveUserProfileToLocalStorage(profileData);
  await saveTokenToLocalStorage(access_token);
  await saveRefreshTokenToLocalStorage(refresh_token);

  return {
    username: userLogin.username,
    access_token,
    refresh_token,
    profileData,
  };
};

const verifyUserData = async (
  verifyData: UserVerifyAccountRequest,
  access_token: any
) => {
  const responseLogin: ApiResponseAuth = await genericRequest(
    Endpoints.AUTH.VERIFY,
    verifyData,
    access_token
  );
  const username = verifyData.email;
  const access_token_response = responseLogin.token;
  const refresh_token = responseLogin.token;

  if (access_token !== undefined) {
    await saveUserProfileToLocalStorage({ username: username });
    await saveTokenToLocalStorage(access_token_response);
    await saveRefreshTokenToLocalStorage(refresh_token);
  }
  return {
    username,
    access_token,
    refresh_token,
  };
};

const genericRequest = async (method: string, body: any, token?: string) => {
  const response = await apiPost({
    url: `${API_URL_IOF}/${method}`,
    body: body,
    unauthorizedCallback: () => {
      unauthorizedCallback();
    },
    token: token,
  });

  if (response.code === 400 || response.error_description) {
    throw new Error(response.error_description);
  }

  return response;
};

const resetUser = async (username: string) => {};

const logout = async () => {
  await asyncLocalStorage.removeItem(LOCAL_STORAGE_JWT);
  await asyncLocalStorage.removeItem(LOCAL_STORAGE_REFRESH_JWT);
  await asyncLocalStorage.removeItem(LOCAL_STORAGE_PROFILE);
  window.location.reload();
};

function isJSON(value: any) {
  try {
    const isjson = JSON.parse(value);
    return isjson;
  } catch (e) {
    return false;
  }
}

const getCurrentUser = () => {
  const jsonProfile = localStorage.getItem(LOCAL_STORAGE_PROFILE);
  if (!jsonProfile || !isJSON(jsonProfile)) {
    return undefined;
  }
  const user: any = JSON.parse(jsonProfile) as any;
  return user.fullname;
};

const getCurrentAlert = () => {
  return localStorage.getItem(LAST_ALERT) || undefined;
};

const getCurrentProfileUser = () => {
  const user = localStorage.getItem(LOCAL_STORAGE_PROFILE);
  if (user && isJSON(user)) return user;
  return undefined;
};

const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_JWT) || undefined;
};

const getRefreshToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_REFRESH_JWT) || undefined;
};

const isLoggedIn = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_JWT);
  const refresh_token = localStorage.getItem(LOCAL_STORAGE_REFRESH_JWT);
  const user = localStorage.getItem(LOCAL_STORAGE_PROFILE);

  return !!token && !!refresh_token && isJSON(user);
};

const saveUserProfileToLocalStorage = async (profile: any) => {
  await asyncLocalStorage.setItem(
    LOCAL_STORAGE_PROFILE,
    JSON.stringify(profile)
  );
};

const saveUserLastAlarmNotification = async (alertId: any) => {
  const alertIdFromLocalStorage = getCurrentAlert();
  if (alertIdFromLocalStorage !== alertId) {
    await asyncLocalStorage.setItem(LAST_ALERT, alertId.toString());
  }
};

const saveTokenToLocalStorage = async (token: string) => {
  await asyncLocalStorage.setItem(LOCAL_STORAGE_JWT, token);
};

const saveRefreshTokenToLocalStorage = async (token: string) => {
  await asyncLocalStorage.setItem(LOCAL_STORAGE_REFRESH_JWT, token);
};

const unauthorizedCallback = async () => {
  logout();
};

export {
  getToken,
  getRefreshToken,
  getCurrentUser,
  getCurrentAlert,
  getCurrentProfileUser,
  saveUserProfileToLocalStorage,
  saveRefreshTokenToLocalStorage,
  saveUserLastAlarmNotification,
  isLoggedIn,
  logout,
  loginWithUsernameAndPassword,
  unauthorizedCallback,
  resetUser,
  verifyUserData,
  isJSON,
};
