export const API_URL_IOF = `${process.env.REACT_APP_API_IOF}`;
export const API_URL_AUTH = `${process.env.REACT_APP_API_URL_AUTH}`;
export const API_WOZALABS_URL = `${process.env.REACT_APP_API_WOZALABS}`;
export const LOCAL_STORAGE_JWT = "IOF.JWT";
export const LOCAL_STORAGE_REFRESH_JWT = "IOF.REFRESH_JWT";
export const LOCAL_STORAGE_PROFILE = "IOF.LOCAL_STORAGE_PROFILE";
export const LAST_ALERT = "IOF.LAST_ALERT";
export const GOOGLE_MAPS_KEY = `${process.env.REACT_APP_GOOGLE_MAPS_KEY || ""}`;
export const S3_ACCESS_KEY = `${process.env.REACT_APP_S3_ACCESS_KEY || ""}`;
export const S3_SECRET_KEY = `${process.env.REACT_APP_S3_SECRET_KEY || ""}`;
export const S3_BUCKET_NAME = `${process.env.REACT_APP_S3_BUCKET_NAME || ""}`;
export const S3_REGION = `${process.env.REACT_APP_S3_REGION || ""}`;
