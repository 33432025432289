import { takeLatest, put, call } from "redux-saga/effects";

import { CLEAN_USER, LOGIN_USER, VERIFY_USER_ACTION } from "./actionTypes";

import {
  cleanStateActionFail,
  cleanStateActionSuccess,
  loginUserFail,
  loginUserSuccess,
  verifyUserFail,
  verifyUserSuccess,
} from "./actions";

import {
  loginWithUsernameAndPassword,
  verifyUserData,
} from "../../core/auth/AuthService";

function* onLoginUser(payload: any) {
  try {
    const { username, password } = payload;
    const response: any[] = yield call(() => {
      return Promise.resolve(
        loginWithUsernameAndPassword({ username: username, password: password })
      );
    });
    yield put(loginUserSuccess(response));
  } catch (error: any) {
    yield put(loginUserFail(error.message));
  }
}

function* onCleanUserState() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanStateActionSuccess());
  } catch (error: any) {
    yield put(cleanStateActionFail(error.response));
  }
}

function* onVerifyUser(payload: any) {
  try {
    const { access_token } = payload;
    const response: any[] = yield call(() => {
      return Promise.resolve(verifyUserData(payload.payload, access_token));
    });
    yield put(verifyUserSuccess(response));
  } catch (error: any) {
    yield put(verifyUserFail(error.message));
  }
}

function* UserSaga() {
  yield takeLatest(LOGIN_USER, onLoginUser);
  yield takeLatest(CLEAN_USER, onCleanUserState);
  yield takeLatest(VERIFY_USER_ACTION, onVerifyUser);
}

export default UserSaga;
