import * as types from "./actionTypes";

const initialState = {
  provinces: [],
  localities: [],
  cities: [],
  permissions: [],
  rolesList: undefined,
  usersAdminList: undefined,
  deviceList: undefined,
  countrysidesList: undefined,
  companiesList: undefined,
  fullCompaniesList: undefined,
  lotsList: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const AdministratorReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.CLEAR_LOTS_LIST:
      state = { ...state, loading: true };
      break;
    case types.GET_CITY:
      state = { ...state, loading: true };
      break;
    case types.GET_LOCALITY:
      state = { ...state, loading: true };
      break;
    case types.GET_PROVINCE:
      state = { ...state, loading: true };
      break;
    case types.UPDATE_ALLOWED_ESTABLISHMENT:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.CLEAR_LOTS_LIST_SUCCESS:
      state = { ...state, loading: false, lotsList: undefined };
      break;

    case types.GET_CITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        cities: actionPayload.response,
        localities: actionPayload.cleanData ? [] : state.localities,
      };
      break;
    case types.GET_ADMIN_DATA:
      state = { ...state, loading: true };
      break;

    case types.GET_ADMIN_DATA_SUCCESS:
      const countrysidesLocalList: any = [];
      const companiesLocalList: any = [];
      let devicesLocalList: any = [];
      let users: any = [];

      if (actionPayload && actionPayload.length > 0) {
        actionPayload.forEach((value: any) => {
          users = value.users;
          devicesLocalList = value.devices;
          value.companies.forEach((val: any) => {
            const { countrysides, addresses, ...rest } = val;
            const addressValues =
              addresses && addresses.locality_id
                ? {
                    address_id: addresses.id,
                    country_l1_name: addresses.localities.country_l1_name,
                    locality_id: addresses.locality_id,
                    municipality_id: addresses.localities.municipality_id,
                    street_name: addresses.street_name,
                    street_number: addresses.street_number,
                    floor: addresses.floor,
                    apartment: addresses.apartment,
                    zip_code: addresses.zip_code,
                  }
                : {};
            companiesLocalList.push({ ...rest, ...addressValues });
            countrysides.forEach((value: any) => {
              countrysidesLocalList.push({ ...value, companies: val });
            });
          });
        });
      }
      state = {
        ...state,
        loading: false,
        usersAdminList: users,
        deviceList: devicesLocalList,
        countrysidesList: countrysidesLocalList,
        companiesList: companiesLocalList,
      };
      break;
    case types.GET_PROVINCE_SUCCESS:
      state = {
        ...state,
        loading: false,
        provinces: actionPayload.response,
        localities: actionPayload.cleanData ? [] : state.localities,
      };
      break;

    case types.GET_PERMISSIONS_LIST:
      state = { ...state, loading: true };
      break;
    case types.GET_PERMISSIONS_LIST_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_PERMISSIONS_LIST_SUCCESS:
      state = { ...state, loading: false, permissions: actionPayload };
      break;

    case types.GET_LOCALITY_SUCCESS:
      state = { ...state, loading: false, localities: actionPayload };
      break;
    case types.GET_ROLES_LIST:
      state = { ...state, loading: true };
      break;
    case types.GET_ROLES_LIST_SUCCESS:
      const formattedRoles = actionPayload.map((val: any) => {
        const permissions = val.role_permissions.map((value: any) => {
          return {
            name: value.permissions.name,
            active: value.permissions.active,
            id: value.permissions.id,
          };
        });
        return { ...val, permissions };
      });

      state = { ...state, loading: false, rolesList: formattedRoles };
      break;
    case types.GET_ESTABLISHMENTS:
      state = { ...state, loading: true };
      break;

    case types.GET_ESTABLISHMENTS_SUCCESS:
      state = { ...state, loading: false, countrysidesList: actionPayload };
      break;

    case types.UPDATE_ALLOWED_ESTABLISHMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;

    case types.CLEAR_LOTS_LIST_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_ADMIN_DATA_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;

    case types.GET_CITY_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_ESTABLISHMENTS_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_LOCALITY_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_PROVINCE_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_ROLES_LIST_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.UPDATE_ALLOWED_ESTABLISHMENT_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
  }

  return state;
};

export default AdministratorReducer;
