import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { useDraggable } from "react-use-draggable-scroll";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import ProfileIcon from "../../../assets/icons/DashboardPage/profileIcon.png";
import SearchIcon from "../../../assets/icons/svg/DashboardPage/search-icon.svg";
import BackArrowIcon from "../../../assets/icons/svg/SiloBagPage/backArrow.svg";
import DashboardLogo from "../../../assets/img/Dashboard/dashboardLogo.svg";
import ToggleIcon from "../../../assets/img/Dashboard/toggle.svg";
import { Paths } from "../../../config/paths";
import {
  findIndex,
  getUserProfile,
  scrollNavOverflowHandler,
} from "../../../shared/helper";
import {
  NavBarProps,
  OrganizationProps,
  ReduxType,
} from "../../../shared/types";
import { setOrganizationAction } from "../../../store/organization/actions";
import { getAllSiloBagAction } from "../../../store/silobag/actions";
import { AlertsDrawerComponent } from "../../alerts/alertsDrawer";

const DropdownIndicator = () => {
  return <></>;
};

const Control = ({ children, ...props }: any) => (
  <components.Control {...props} className="cursor-text ellipsis">
    {children}
  </components.Control>
);

const NavBarComponent = (props: NavBarProps) => {
  const [organization, setOrganization] = useState<any>();
  const [userEmail, setUserEmail] = useState<any>();
  const [userPermissions, setUserPermissions] = useState<any[]>([]);
  const [menuOptions, setMenuOptions] = useState<any[]>([]);
  const [displayResponsiveMenu, setDisplayResponsiveMenu] =
    useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData, orgData, siloBagData } = useSelector((state: ReduxType) => {
    return state;
  });

  const { path, mobile } = props;

  const menu = [
    {
      name: "Dashboard",
      className: path == Paths.ROOT ? "navBarLinkSelected" : "",
      id: 1,
      onClick: () => navigate(Paths.ROOT),
    },
    {
      name: "Mapa",
      className: path == Paths.COUNTRYSIDES_MAPS ? "navBarLinkSelected" : "",
      id: 2,
      onClick: () => navigate(Paths.COUNTRYSIDES_MAPS),
    },
  ];

  const mobileMenu = [
    { name: "Clima", onClick: () => navigate("http://clima.iofcompany.com") },
    {
      name: "Mi perfil",
      className: path == Paths.SILO ? "navBarLinkSelected" : "",
      onClick: () => navigate(Paths.SILO),
    },
    {
      name: "Cambiar Contraseña",
      onClick: () =>
        navigate(Paths.RESET, {
          state: {
            from: Paths.NAV,
          },
        }),
    },
  ];

  useEffect(() => {
    const scrollNav = document.querySelector("#scroll-nav");
    if (!scrollNav) return () => {};
    if (scrollNav)
      scrollNavOverflowHandler({
        currentTarget: scrollNav,
      } as React.UIEvent<HTMLElement>);

    scrollNav.addEventListener("scroll", (e: any) =>
      scrollNavOverflowHandler(e)
    );
    return () => {
      scrollNav.removeEventListener("scroll", (e: any) =>
        scrollNavOverflowHandler(e)
      );
    };
  }, []);

  useEffect(() => {
    if (userData.userProfile) {
      const user = getUserProfile(userData);
      if (!userEmail) {
        setUserEmail(user.email);
      }
      setUserPermissions(user.user_permissions);
      setOrganization(user.organization_id);
      if (
        user.organizations &&
        user.organizations.length > 0 &&
        !organization &&
        !orgData.currentOrganization
      ) {
        setCurrentOrg(user.organizations[0]);
      } else if (
        user.organization_id &&
        !organization &&
        !orgData.currentOrganization
      ) {
        setCurrentOrg({
          id: user.organization_id,
          legal_name:
            user && user.organizations && user.organizations.legal_name,
          user_id: user.id,
        });
      }
    }
  }, [userData.userProfile]);

  useEffect(() => {
    if (!userPermissions || userPermissions.length === 0) {
      setMenuOptions(menu);
    } else {
      const menuPermissions = [...menu];
      userPermissions.forEach((val) => {
        if (!val.active) return;
        if (
          val &&
          ((val.permissions && val.permissions.name === "Silos") ||
            val.permission_id == 10)
        )
          menuPermissions.push({
            name: "Silobolsas",
            id: 3,
            className:
              path == Paths.SILO ||
              path == Paths.NEW_SILO ||
              path == Paths.VIEW_SILO
                ? "navBarLinkSelected"
                : "",
            onClick: () => navigate(Paths.SILO),
          });
        if (
          !menuPermissions.find(
            (value: any) => value.name === "Administrador"
          ) &&
          [
            2, 3, 6, 8,
            /*
              "Compañías",
              "Establecimientos",
              "Métricas alertas",
              "Usuarios",
            */
          ].includes(val.permission_id) //val.permissions.name
        ) {
          menuPermissions.push({
            name: "Administrador",
            className: path == Paths.ADMINISTRATOR ? "navBarLinkSelected" : "",
            id: 4,
            onClick: () => navigate(Paths.ADMINISTRATOR),
          });
        }
      });
      setMenuOptions(
        menuPermissions.sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        })
      );
    }
  }, [userPermissions]);

  useEffect(() => {
    if (orgData.currentOrganization && orgData.currentOrganization.id) {
      getSiloBagList(orgData.currentOrganization.id);
    }
  }, [orgData.currentOrganization]);

  const getSiloBagList = async (organization_id: number) => {
    await dispatch(getAllSiloBagAction());
  };

  const setCurrentOrg = async (org: OrganizationProps) => {
    await dispatch(setOrganizationAction(org));
  };

  const getUserFirstLetter = () => {
    const user = getUserProfile(userData);
    return user && user.fullname && user.fullname.charAt(0)
      ? user.fullname.charAt(0)
      : "";
  };

  const SeachInput = (
    <Form>
      <div className="navBarInputSize">
        <Form.Group controlId="form-sil" className="input-search">
          <InputGroup hasValidation className="no-wrap">
            <Select
              className={"search-input-navbar"}
              placeholder="Buscar silo o lanza"
              components={{ DropdownIndicator, Control }}
              options={
                siloBagData.silobagList && siloBagData.silobagList.length > 0
                  ? siloBagData.silobagList
                      .filter((value: any) =>
                        searchValue === ""
                          ? false
                          : value.name.toUpperCase().indexOf(searchValue) > -1
                      )
                      .map((value: any) => ({
                        value,
                        label: value.name,
                      }))
                  : []
              }
              onInputChange={(e: any) => setSearchValue(e.toUpperCase())}
              onChange={(e: any) =>
                navigate(Paths.VIEW_SILO, {
                  state: {
                    from: path,
                    record: e.value,
                  },
                })
              }
            />
            <InputGroup.Text
              id="inputGroupPrepend"
              className={"navBarInputSearch"}
            >
              <Image style={{ height: "12.28", width: 13 }} src={SearchIcon} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </div>
    </Form>
  );

  const SeachInputExtended = (
    <Form className="w-100">
      <div className="navBarInputSize w-100">
        <Form.Group controlId="form-sil" className="input-search w-100">
          <InputGroup hasValidation className="no-wrap">
            <Select
              className="search-input-navbar-extended"
              placeholder="Buscar silo o lanza"
              components={{ DropdownIndicator, Control }}
              options={
                siloBagData.silobagList && siloBagData.silobagList.length > 0
                  ? siloBagData.silobagList
                      .filter((value: any) =>
                        searchValue === ""
                          ? false
                          : value.name.toUpperCase().indexOf(searchValue) > -1
                      )
                      .map((value: any) => ({
                        value,
                        label: value.name,
                      }))
                  : []
              }
              onInputChange={(e: any) => setSearchValue(e.toUpperCase())}
              onChange={(e: any) =>
                navigate(Paths.VIEW_SILO, {
                  state: {
                    from: path,
                    record: e.value,
                  },
                })
              }
            />
            <InputGroup.Text
              id="inputGroupPrepend"
              className={"navBarInputSearch"}
            >
              <Image style={{ height: "12.28", width: 13 }} src={SearchIcon} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </div>
    </Form>
  );

  return (
    <>
      <Navbar bg="light" expand="md">
        <Container fluid style={{ height: "3em" }}>
          <>
            {isSearchOpen ? (
              <div className="d-inline-flex justify-between w-100">
                <Button
                  onClick={() => setIsSearchOpen(false)}
                  style={{ background: "transparent", border: 0 }}
                  className={"link-decorator-none d-inline-flex align-center"}
                >
                  <Image
                    style={{ height: 20 }}
                    className={"align-middle"}
                    src={BackArrowIcon}
                  />
                </Button>
                <Nav.Link className={"d-flex w-100"}>
                  {SeachInputExtended}
                </Nav.Link>
              </div>
            ) : (
              <>
                <Navbar.Brand>
                  <Link to={Paths.ROOT}>
                    <img
                      src={DashboardLogo}
                      className="dashboard-logo d-inline-block align-top "
                      alt="React Bootstrap logo"
                    />
                  </Link>
                </Navbar.Brand>
                <Nav
                  className="align-center d-flex"
                  style={
                    mobile
                      ? { maxHeight: "100px" }
                      : { maxHeight: "100px", width: "24%" }
                  }
                >
                  <div
                    className={"d-inline-flex align-center"}
                    style={{
                      justifyContent: "flex-end",
                    }}
                  >
                    {!mobile ? (
                      <Nav.Link className={"d-flex"}>{SeachInput}</Nav.Link>
                    ) : (
                      <Nav.Link
                        className="d-flex"
                        onClick={() => setIsSearchOpen(!isSearchOpen)}
                      >
                        <Image
                          style={{ width: 18, height: 18 }}
                          src={SearchIcon}
                        />
                      </Nav.Link>
                    )}

                    {mobile && (
                      <>
                        <AlertsDrawerComponent />
                        <Nav.Link
                          className={"d-flex"}
                          onClick={() =>
                            setDisplayResponsiveMenu(!displayResponsiveMenu)
                          }
                        >
                          <Image
                            className={"dashboard-toggle-icon"}
                            src={ToggleIcon}
                          />
                          <div className={"navBarWithImage"}>
                            <img
                              src={ProfileIcon}
                              alt="user pic"
                              className="navBarUserCircle"
                            />
                            <span className="navBarUserLetter">
                              {getUserFirstLetter()}
                            </span>
                          </div>
                        </Nav.Link>
                      </>
                    )}
                  </div>
                </Nav>
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  style={{
                    justifyContent: "flex-end",
                    width: "60%",
                    display: mobile ? "none" : "initial",
                  }}
                >
                  <div
                    {...events}
                    className="d-inline-flex align-center navbar-nav-menu"
                    id="scroll-nav"
                    ref={ref}
                    style={{ width: "80%" }}
                  >
                    <Nav
                      style={{ maxHeight: "100px", marginLeft: "auto" }}
                    ></Nav>
                    {menuOptions &&
                      menuOptions.map((m, index: number) => {
                        // CHANGE ADMIN VALIDATION
                        if (!userData.userProfile) return;

                        return (
                          <Nav.Link
                            key={`main-menu-${index}`}
                            className={m.className}
                            onClick={m.onClick}
                          >
                            {m.name}
                          </Nav.Link>
                        );
                      })}
                    <Nav.Link
                      href="http://clima.iofcompany.com"
                      target={"_blank"}
                    >
                      Clima
                    </Nav.Link>
                  </div>

                  {!mobile && <AlertsDrawerComponent />}

                  <NavDropdown
                    align="end"
                    title={
                      <div className={"navBarWithImage"}>
                        <span className="navBarUserLetter ">
                          {getUserFirstLetter()}
                        </span>
                      </div>
                    }
                  >
                    <NavDropdown.Item
                      eventKey={1.4}
                      onClick={() =>
                        navigate(Paths.RESET, {
                          state: {
                            from: Paths.NAV,
                          },
                        })
                      }
                    >
                      Cambiar Contraseña
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      eventKey={1.3}
                      onClick={() => navigate(Paths.LOGOUT)}
                    >
                      Cerrar Sesión
                    </NavDropdown.Item>
                  </NavDropdown>
                </Navbar.Collapse>
              </>
            )}
          </>
        </Container>
      </Navbar>
      <Offcanvas
        show={displayResponsiveMenu}
        onHide={setDisplayResponsiveMenu}
        placement="end"
        className={"dashboard-canvas-main"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fw-bold-500 canvas-main-dashboard-title">
            {userEmail}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={"custom-canvas-body"}>
          {[...menuOptions, ...mobileMenu].map((m, index: number) => {
            return (
              <Nav.Link
                key={`mobile-menu-${index}`}
                className={m.className}
                onClick={m.onClick}
              >
                {m.name}
              </Nav.Link>
            );
          })}

          <Nav.Link
            style={{ position: "absolute", bottom: 0, marginBottom: 15 }}
            onClick={() => navigate(Paths.LOGOUT)}
          >
            <div className={"divider"} />
            Cerrar sesión
          </Nav.Link>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NavBarComponent;
