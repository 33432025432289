import * as types from "./actionTypes";

export const getDashboardDataAction = () => {
  return {
    type: types.GET_DASHBOARD_DATA,
  };
};

export const getDashboardDataFail = (error: any) => {
  return {
    type: types.GET_DASHBOARD_DATA_FAIL,
    payload: error,
  };
};

export const getDashboardDataSuccess = (dashboard: any) => {
  return {
    type: types.GET_DASHBOARD_DATA_SUCCESS,
    payload: dashboard,
  };
};
