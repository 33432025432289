import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Paths } from "../../config/paths";
import loadable from "@loadable/component";

const fallback = {
  fallback: <LoadableLoadingComponent />,
};

const DashboardPage = loadable(
  () => import("../../pages/dashboard/DashboardPage"),
  fallback
);
const ForgotPasswordPage = loadable(
  () => import("../../pages/forgot/ForgotPasswordPage"),
  fallback
);
const LoginPage = loadable(
  () => import("../../pages/login/LoginPage"),
  fallback
);
const LogoutPage = loadable(
  () => import("../../pages/logout/LogoutPage"),
  fallback
);
const NewSilobagPage = loadable(
  () => import("../../pages/silobag/NewSilobagPage"),
  fallback
);
const Administrator = loadable(
  () => import("../../pages/administrator/Administrator"),
  fallback
);
const SiloBagPage = loadable(
  () => import("../../pages/silobag/SiloBagPage"),
  fallback
);
const ViewSiloBagPage = loadable(
  () => import("../../pages/silobag/ViewSiloBagPage"),
  fallback
);
const CountrysidesMapPage = loadable(
  () => import("../../pages/countrysidesMap/CountrysidesMap"),
  fallback
);
const AlertPage = loadable(
  () => import("../../pages/metric/MetricPage"),
  fallback
);
const NotificationPage = loadable(
  () => import("../../pages/notification/NotificationPage"),
  fallback
);
const VerifyAccountPage = loadable(
  () => import("../../pages/verify/VerifyAccountPage"),
  fallback
);
const VerifyResetPaget = loadable(
  () => import("../../pages/verify/VerifyResetPaget"),
  fallback
);
const ResetPage = loadable(
  () => import("../../pages/reset/ResetPage"),
  fallback
);
const SiloBagMapPage = loadable(
  () => import("../../pages/silobag/SiloBagMapPage"),
  fallback
);
const VerifyPage = loadable(
  () => import("../../pages/verify/VerifyPage"),
  fallback
);
import NoConnectionPage from "../../pages/noConnection/NoConnectionPage";
import { LoadableLoadingComponent } from "../../components/base/loadableLoading";

export const RouterConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={Paths.ALERT} element={<AlertPage />} />
        <Route path={Paths.ADMINISTRATOR} element={<Administrator />} />
        <Route path={Paths.NO_CONNECTION} element={<NoConnectionPage />} />
        <Route
          path={Paths.COUNTRYSIDES_MAPS}
          element={<CountrysidesMapPage />}
        />
        <Route path={Paths.FORGOTPASS} element={<ForgotPasswordPage />} />
        <Route path={Paths.LOGIN} element={<LoginPage />} />
        <Route path={Paths.LOGOUT} element={<LogoutPage />} />
        <Route path={Paths.NEW_SILO} element={<NewSilobagPage />} />
        <Route path={Paths.NOTIFICATION} element={<NotificationPage />} />
        <Route path={Paths.ROOT} element={<DashboardPage />} />
        <Route path={Paths.SILO} element={<SiloBagPage />} />
        <Route path={Paths.SILOBAG_MAP} element={<SiloBagMapPage />} />
        <Route path={Paths.VIEW_SILO} element={<ViewSiloBagPage />} />
        <Route path={Paths.RESET} element={<ResetPage />} />
        <Route path={Paths.VERIFY} element={<VerifyPage />} />
        <Route path={Paths.VERIFYACCOUNT} element={<VerifyAccountPage />} />
        <Route path={Paths.VERIFYRESETACCOUNT} element={<VerifyResetPaget />} />
      </Routes>
    </BrowserRouter>
  );
};
