import { combineReducers } from "redux";
import AdministratorReducer from "./administrator/reducer";
import AlertsReducer from "./alerts/reducer";
import DashboardReducer from "./dashboard/reducer";
import CountrysidesMapReducer from "./countrysidesMap/reducer";
import OrganizationReducer from "./organization/reducer";
import SiloBagReducer from "./silobag/reducer";
import UserReducer from "./users/reducer";
import NotificationReducer from "./notification/reducer";

const rootReducer = combineReducers({
  dashboardData: DashboardReducer,
  orgData: OrganizationReducer,
  userData: UserReducer,
  countrysidesMapData: CountrysidesMapReducer,
  metricsData: AlertsReducer,
  siloBagData: SiloBagReducer,
  administratorData: AdministratorReducer,
  notificationData: NotificationReducer,
});

export default rootReducer;
