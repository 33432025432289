import React, { useEffect, useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";

import ReactMarkdown from "react-markdown";
import { getMarkdownContent } from "../../../shared/helper";

const FooterComponent = (props: any) => {
  const { width } = props;
  const [content, setContent] = useState("");
  const [autoSize, setAutoSize] = useState<boolean>(false);

  useEffect(() => {
    getContent();
  }, []);

  useEffect(() => {
    setAutoSize(width <= 768);
  }, [width]);

  const getContent = async () => {
    const readmePath = require("../../../version.md");
    setContent(await getMarkdownContent(readmePath));
  };

  const links = [
    {
      name: "Política de privacidad",
      link: "https://app.iofcompany.com/privacy.html",
    },
    {
      name: "Términos y condiciones",
      link: "https://app.iofcompany.com/legal.html",
    },
    {
      name: "Contacto",
      link: "mailto:info@iofcompany.com",
    },
  ];
  return (
    <>
      <Row className="footer justify-content-between align-items-center">
        <Col xs="auto" className={"footerLinks"}>
          <div
            className="d-flex align-items-center"
            style={{
              columnGap: "0.5rem",
              flexWrap: "nowrap",
              padding: "0.2rem",
            }}
          >
            <div>©2022</div>
            <div
              className="d-flex align-items-center"
              style={{ marginLeft: "-0.1rem" }}
            >
              <ReactMarkdown children={content} />
            </div>
          </div>
        </Col>
        {autoSize ? (
          <>
            {links.map((l, index: number) => {
              return (
                <Col
                  key={`footer-key-${index}`}
                  xs="auto"
                  className={"footerLinks"}
                >
                  <Nav.Link className={"footerCustomLink"} href={l.link}>
                    {l.name}
                  </Nav.Link>
                </Col>
              );
            })}
          </>
        ) : (
          <Col xs="auto">
            <Row>
              {links.map((l, index: number) => {
                return (
                  <Col key={`footer-key-${index}`} xs="auto">
                    <Nav.Link className={"footerCustomLink"} href={l.link}>
                      {l.name}
                    </Nav.Link>
                  </Col>
                );
              })}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default FooterComponent;
