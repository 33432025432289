import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../config/paths";
import { AuthenticatedRouteProps, ReduxType } from "../../shared/types";
import { isLoggedIn } from "../auth/AuthService";
import LoginLayaout from "../layaout/LoginLayaout";
import MainLayaout from "../layaout/MainLayaout";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getUserProfile, isCrudEnabled } from "../../shared/helper";

const isAuthPath = (path: string) =>
  path !== Paths.LOGIN &&
  path !== Paths.RESET &&
  path !== Paths.FORGOTPASS &&
  path !== Paths.VERIFYACCOUNT &&
  path !== Paths.VERIFYRESETACCOUNT;

const isLoginLayout = (path: string) =>
  path === Paths.LOGIN ||
  path === Paths.FORGOTPASS ||
  path === Paths.VERIFYACCOUNT ||
  path === Paths.VERIFYRESETACCOUNT ||
  path === Paths.RESET;

const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
  const {
    children,
    loading,
    path,
    setErrorType,
    setErrorMessage,
    setShowToast,
  } = props;

  const [localLoading, setLocalLoading] = useState(true);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const navigate = useNavigate();

  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (!isLoggedIn() && isAuthPath(path)) {
      return navigate(Paths.LOGIN);
    }
    if (!isOnline && path !== Paths.NO_CONNECTION) {
      return navigate(Paths.NO_CONNECTION);
    }
    isUrlAvailable();
    localLoading && setLocalLoading(false);
  }, [path]);

  const isUrlAvailable = () => {
    if (userData.userProfile) {
      const userProfile = getUserProfile(userData);
      const isEnabled = isCrudEnabled(userProfile, path);
      if (!isEnabled) return navigate(Paths.ROOT);
    }
  };

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      setErrorMessage(
        navigator.onLine
          ? "Conexion a internet."
          : "Conexion a internet perdida."
      );
      setShowToast(true);
      setErrorType(navigator.onLine ? false : true);
    };

    window.addEventListener("online", handleStatusChange);

    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const getChildrenOrSpinner = () => {
    return (
      <>
        {!localLoading && !loading ? (
          <>{children}</>
        ) : (
          <>
            <Spinner
              className="centered-spinner"
              animation="border"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </>
        )}
      </>
    );
  };
  return (
    <div className="page-container">
      {isLoginLayout(path) ? (
        <LoginLayaout {...props} width={width}>
          {getChildrenOrSpinner()}
        </LoginLayaout>
      ) : (
        <MainLayaout {...props} width={width}>
          {getChildrenOrSpinner()}
        </MainLayaout>
      )}
    </div>
  );
};

export default AuthenticatedRoute;
