import * as types from "./actionTypes";

const initialState = {
  userToNotify: [],
  display: false,
  loading: false,
  error: {
    message: "",
  },
};

const NotificationReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case types.GET_USER_TO_NOTIFY_ACTION:
    case types.CLEAR_NOTIFICATION_DATA_ACTION:
      state = {
        ...state,
        loading: true,
      };
      break;

    case types.GET_USER_TO_NOTIFY_SUCCESS:
      state = {
        ...state,
        loading: false,
        userToNotify: actionPayload,
      };
      break;

    case types.CLEAR_NOTIFICATION_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        userToNotify: [],
      };
      break;

    case types.GET_USER_TO_NOTIFY_FAIL:
    case types.CLEAR_NOTIFICATION_DATA_FAIL:
      state = {
        ...state,
        error: {
          message: actionPayload,
        },
        loading: false,
      };
      break;
  }

  return state;
};

export default NotificationReducer;
