import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL_IOF, API_WOZALABS_URL } from "../../config/general-config";
import { apiGet } from "../../shared/ApiService";
import {
  clearNotificationDataFail,
  clearNotificationDataSuccess,
  getUserToNotifyFail,
  getUserToNotifySuccess,
} from "./actions";

import {
  CLEAR_NOTIFICATION_DATA_ACTION,
  GET_USER_TO_NOTIFY_ACTION,
} from "./actionTypes";

function* onGetUserToNotify(action: any) {
  try {
    const { org_id } = action.payload;
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.NOTIFICATION}`,
        })
      );
    });
    yield put(getUserToNotifySuccess(response));
  } catch (error: any) {
    yield put(getUserToNotifyFail(error.response));
  }
}

function* onClearNotificationData(action: any) {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve();
    });
    yield put(clearNotificationDataSuccess());
  } catch (error: any) {
    yield put(clearNotificationDataFail(error.response));
  }
}

function* NotificationsSaga() {
  yield takeLatest(GET_USER_TO_NOTIFY_ACTION, onGetUserToNotify);
  yield takeLatest(CLEAR_NOTIFICATION_DATA_ACTION, onClearNotificationData);
}

export default NotificationsSaga;
