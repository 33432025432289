import React, { useState } from "react";
import Desplegable from "../../../../assets/icons/svg/Alerts/desplegable.svg";

import Clock from "../../../../assets/icons/svg/Alerts/clock.svg";

export const ItemDropdown = (props: any) => {
  const [enabled, setEnabled] = useState(false);
  const { id, onClick } = props;

  const onClickOption = (option: 7 | 15 | 30) => {
    onClick({ alert_actions_type_id: id, reminder: option });
  };

  return (
    <div style={{ userSelect: "none" }}>
      <div
        onClick={() => setEnabled(!enabled)}
        className="d-inline-flex dropdown-item align-center justify-between"
      >
        <div>
          <img
            src={Clock}
            alt="dng-icon"
            style={{ marginRight: 10, width: 12, height: 12 }}
          />
          <span>Recordarme en</span>
        </div>

        <img src={Desplegable} alt="alt-icon" />
      </div>
      {enabled && (
        <div
          className="d-flex"
          style={{ flexDirection: "column", gap: 5, marginTop: 3 }}
        >
          <div className="dropdown-item" onClick={() => onClickOption(7)}>
            <span style={{ marginLeft: 10 }}>7 dias</span>
          </div>
          <div className="dropdown-item" onClick={() => onClickOption(15)}>
            <span style={{ marginLeft: 10 }}>15 dias</span>
          </div>
          <div className="dropdown-item" onClick={() => onClickOption(30)}>
            <span style={{ marginLeft: 10 }}>30 dias</span>
          </div>
        </div>
      )}
    </div>
  );
};
