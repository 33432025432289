import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL_IOF } from "../../config/general-config";
import { apiGet } from "../../shared/ApiService";
import { ApiResponse } from "../../shared/types";
import {
  clearCountrysidesLotsFail,
  clearCountrysidesLotsSuccess,
  getAdminDataFail,
  getAdminDataSuccess,
  getCitiesListDataFail,
  getCitiesListDataSuccess,
  getLocalitiesListDataFail,
  getLocalitiesListDataSuccess,
  getPermissionsListFail,
  getPermissionsListSuccess,
  getRolesListFail,
  getRolesListSuccess,
} from "./actions";
import {
  CLEAR_LOTS_LIST,
  GET_ADMIN_DATA,
  GET_CITY,
  GET_LOCALITY,
  GET_PERMISSIONS_LIST,
  GET_ROLES_LIST,
} from "./actionTypes";

function* onGetCities(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.CITY}`,
        })
      );
    });
    yield put(
      getCitiesListDataSuccess({
        response: response,
        cleanData: action.cleanData,
      })
    );
  } catch (error) {
    yield put(getCitiesListDataFail(error));
  }
}

function* onGetLocalities(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.LOCALITIES}`,
        })
      );
    });
    yield put(getLocalitiesListDataSuccess(response));
  } catch (error) {
    yield put(getLocalitiesListDataFail(error));
  }
}

function* onGetRolesList() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({ url: `${API_URL_IOF}/${Endpoints.API_IOF.ROLES}` })
      );
    });
    yield put(getRolesListSuccess(response));
  } catch (error) {
    yield put(getRolesListFail(error));
  }
}

function* onGetAdminData(payload: any) {
  try {
    const { org_id } = payload;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.ORGANIZATIONS}/${org_id}`,
        })
      );
    });
    yield put(getAdminDataSuccess(response));
  } catch (error) {
    yield put(getAdminDataFail(error));
  }
}

function* onClearLotsListData(payload: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve();
    });
    yield put(clearCountrysidesLotsSuccess(response));
  } catch (error) {
    yield put(clearCountrysidesLotsFail(error));
  }
}

function* onGetPermissions() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.PERMISSIONS}`,
        })
      );
    });
    yield put(getPermissionsListSuccess(response));
  } catch (error) {
    yield put(getPermissionsListFail(error));
  }
}

function* AdministratorSaga() {
  yield takeLatest(CLEAR_LOTS_LIST, onClearLotsListData);
  yield takeLatest(GET_CITY, onGetCities);
  yield takeLatest(GET_LOCALITY, onGetLocalities);
  yield takeLatest(GET_ROLES_LIST, onGetRolesList);
  yield takeLatest(GET_ADMIN_DATA, onGetAdminData);
  yield takeLatest(GET_PERMISSIONS_LIST, onGetPermissions);
}

export default AdministratorSaga;
