import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL_IOF, API_WOZALABS_URL } from "../../config/general-config";
import { apiGet } from "../../shared/ApiService";
import {
  getCountrysideFromSiloByLoteIdFail,
  getCountrysideFromSiloByLoteIdSuccess,
  getCountrysidesMapDataFail,
  getCountrysidesMapDataSuccess,
  getLanzasByLoteIdFail,
  getLanzasByLoteIdSuccess,
} from "./actions";
import {
  GET_COUNTRYSIDES_MAP_DATA,
  GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID,
  GET_LANZAS_BY_LOTE_ID,
} from "./actionTypes";

function* onGetCountrysidesMapData() {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.FIRST_VIEW}`,
        })
      );
    });
    yield put(getCountrysidesMapDataSuccess(response));
  } catch (error: any) {
    yield put(getCountrysidesMapDataFail(error.response));
  }
}

function* onGetCountrysidesFromSiloByLoteId(action: any) {
  try {
    const { user_id, countryside_id, lote_id } = action.payload;

    const responseContryside: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.SECOND_VIEW}/${countryside_id}`,
        })
      );
    });

    let responseInfoWindow: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.SECOND_VIEW_POINT_TOOLTIPS}/${countryside_id}`,
        })
      );
    });

    if (responseInfoWindow) {
      responseInfoWindow = responseInfoWindow.map((r) => {
        return { ...r, countryside_id: countryside_id };
      });
    }

    const response = {
      countryside_map_data: responseContryside,
      infoWindow_map_data: responseInfoWindow,
    };

    yield put(getCountrysideFromSiloByLoteIdSuccess(response));
  } catch (error: any) {
    yield put(getCountrysideFromSiloByLoteIdFail(error.response));
  }
}

function* onGetLanzasByLoteId(action: any) {
  try {
    const { lote_id } = action.payload;

    let response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.SECOND_VIEW_DEVICE_NAV}/${lote_id}`,
        })
      );
    });

    yield put(getLanzasByLoteIdSuccess({ lanzas_map_data: response }));
  } catch (error: any) {
    yield put(getLanzasByLoteIdFail(error.response));
  }
}

function* CountrysidesMapSaga() {
  yield takeLatest(GET_COUNTRYSIDES_MAP_DATA, onGetCountrysidesMapData);
  yield takeLatest(GET_LANZAS_BY_LOTE_ID, onGetLanzasByLoteId);
  yield takeLatest(
    GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID,
    onGetCountrysidesFromSiloByLoteId
  );
}

export default CountrysidesMapSaga;
