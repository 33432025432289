import { takeLatest, put, call } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL_IOF, API_WOZALABS_URL } from "../../config/general-config";
import { apiGet } from "../../shared/ApiService";
import { DashboardProps } from "../../shared/types";
import { getDashboardDataFail, getDashboardDataSuccess } from "./actions";

import { GET_DASHBOARD_DATA } from "./actionTypes";

function* onGetDashboardData() {
  try {
    const responseDashboardInfo: DashboardProps = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.DASHBOARD_INFO}`,
        })
      );
    });

    let response;
    if (responseDashboardInfo) {
      response = {
        siloCount:
          responseDashboardInfo.count_silobags &&
          responseDashboardInfo.count_silobags[0] &&
          responseDashboardInfo.count_silobags[0].count_silobags,
        tonsCount:
          responseDashboardInfo.total_silobags_weight &&
          responseDashboardInfo.total_silobags_weight[0],
        alertsCount:
          responseDashboardInfo.count_alerts &&
          responseDashboardInfo.count_alerts[0] &&
          responseDashboardInfo.count_alerts[0].count_alerts,
        countrysides_data: responseDashboardInfo.count_silobags_per_lot,
        silo_data:
          responseDashboardInfo && responseDashboardInfo.silobags_max_alerts,
        device_data:
          responseDashboardInfo && responseDashboardInfo.total_devices_by_type,
        monitored_tons_crop_data: {
          products: responseDashboardInfo.total_silobags_weight_by_product_type,
          species: responseDashboardInfo.total_silobags_weight_by_species_type,
        },
        monitored_tons_establishment_data:
          responseDashboardInfo.total_silobags_weight_by_countryside,
      };
    }
    yield put(getDashboardDataSuccess(response));
  } catch (error: any) {
    yield put(getDashboardDataFail(error.response));
  }
}

function* DashboardSaga() {
  yield takeLatest(GET_DASHBOARD_DATA, onGetDashboardData);
}

export default DashboardSaga;
