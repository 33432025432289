import React from "react";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

export default () => {
  return (
    <AuthenticatedRoute
      withHeader
      withPadding={false}
      path={Paths.NO_CONNECTION}
      loading={false}
    >
      <div style={{ display: "flex", width: "100%", height: "100%" }}>
        <h2 style={{ margin: "auto" }}>NO INTERNET CONNECTION</h2>
      </div>
    </AuthenticatedRoute>
  );
};
