import { UserLoginRequest, UserVerifyAccountRequest } from "../../shared/types";
import * as types from "./actionTypes";

export const loginUserAction = (user: UserLoginRequest) => {
  return {
    type: types.LOGIN_USER,
    ...user,
  };
};

export const loginUserFail = (error: any) => {
  return {
    type: types.LOGIN_USER_FAIL,
    payload: error,
  };
};

export const loginUserSuccess = (user: any) => {
  return {
    type: types.LOGIN_USER_SUCCESS,
    payload: user,
  };
};

export const cleanStateAction = () => {
  return {
    type: types.CLEAN_USER,
  };
};

export const cleanStateActionFail = (error: any) => {
  return {
    type: types.CLEAN_USER_FAIL,
    payload: error,
  };
};

export const cleanStateActionSuccess = () => {
  return {
    type: types.CLEAN_USER_SUCCESS,
  };
};

export const verifyUserAction = (
  verifyData: UserVerifyAccountRequest,
  access_token: any
) => {
  return { type: types.VERIFY_USER_ACTION, payload: verifyData, access_token };
};

export const verifyUserFail = (error: any) => {
  return { type: types.VERIFY_USER_FAIL, payload: error };
};

export const verifyUserSuccess = (payload: any) => {
  return { type: types.VERIFY_USER_SUCCESS, payload };
};
