import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { AlertActions } from "../../../../shared/types";

import { ItemDropdown } from "./itemDropdown";

interface ICustomDropdown {
  id: any;
  lastActionData: AlertActions;
  onSelect: (alert: any, bool: boolean) => void;
  resetData: boolean;
  alertOptionTypes: { img: any; label: string; id: number }[];
  setResetData: (value: boolean) => void;
}

export const CustomDropdown = (props: ICustomDropdown) => {
  const [dropdownSelected, setDropdownSelected] = useState<any>("Pendiente");
  const [show, setShow] = useState(false);
  const dropdownRef = React.useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) =>
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      setShow(false);

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const {
    alertOptionTypes,
    lastActionData,
    onSelect,
    id,
    resetData,
    setResetData,
  } = props;

  useEffect(() => {
    if (lastActionData) {
      let selectData: any = {
        alert_actions_type_id: lastActionData.alert_actions_type_id,
      };
      let label = lastActionData.alert_actions_types.name;
      if (
        lastActionData &&
        lastActionData.alert_actions_types &&
        lastActionData.alert_actions_types.name == "Recordarme en"
      ) {
        selectData["reminder"] = lastActionData.days_until_next_action;
        label = `Recordarme en ${lastActionData.days_until_next_action} días`;
      }
      onSelect(selectData, false);
      setDropdownSelected(label);
      if (resetData) {
        setResetData(false);
      }
    }
  }, [lastActionData, resetData]);

  const onLocalSelect = (data: {
    alert_actions_type_id: number;
    reminder: number;
  }) => {
    setDropdownSelected(`Recordarme en ${data.reminder} días`);
    onSelect(data, true);
  };

  return (
    <Dropdown
      show={show}
      className="mx-2"
      ref={dropdownRef}
      key={`dropdown-custom_${id}`}
      onClick={(e: any) => {
        e.target.innerText !== "Recordarme en" && setShow(!show);
      }}
      onSelect={(e: any) => {
        if (alertOptionTypes[e]) {
          onSelect({ alert_actions_type_id: alertOptionTypes[e].id }, true);
          setShow(!show);
          setDropdownSelected(alertOptionTypes[e].label);
        }
      }}
    >
      <Dropdown.Toggle id="dropdown-autoclose-true " className="custom-select">
        {dropdownSelected}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {alertOptionTypes &&
          alertOptionTypes.length > 0 &&
          alertOptionTypes.map((ot: any, index: number) =>
            ot.dropdown ? (
              <ItemDropdown
                id={ot.id}
                onClick={onLocalSelect}
                key={`${ot.id}-${index}`}
              />
            ) : (
              <Dropdown.Item
                key={`${ot.id}-${index}`}
                active={dropdownSelected === ot.id}
                eventKey={index}
              >
                <img
                  src={ot.img}
                  alt={`${ot.label}-icon`}
                  style={{ marginRight: 10, width: 12, height: 12 }}
                />
                {ot.label}
              </Dropdown.Item>
            )
          )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
