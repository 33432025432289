export const GET_ALERTS_DATA = "GET_ALERTS_DATA";
export const GET_ALERTS_DATA_FAIL = "GET_ALERTS_DATA_FAIL";
export const GET_ALERTS_DATA_SUCCESS = "GET_ALERTS_DATA_SUCCESS";
export const SET_ALERTS_DISPLAY = "SET_ALERTS_DISPLAY";

export const GET_ALERTS_CONFIG_DATA = "GET_ALERTS_CONFIG_DATA";
export const GET_ALERTS_CONFIG_DATA_FAIL = "GET_ALERTS_CONFIG_DATA_FAIL";
export const GET_ALERTS_CONFIG_DATA_SUCCESS = "GET_ALERTS_CONFIG_DATA_SUCCESS";

export const GET_ALERTS_TYPES_DATA = "GET_ALERTS_TYPES_DATA";
export const GET_ALERTS_TYPES_DATA_FAIL = "GET_ALERTS_TYPES_DATA_FAIL";
export const GET_ALERTS_TYPES_DATA_SUCCESS = "GET_ALERTS_TYPES_DATA_SUCCESS";

export const GET_ALERTS_ZONES_DATA = "GET_ALERTS_ZONES_DATA";
export const GET_ALERTS_ZONES_DATA_FAIL = "GET_ALERTS_ZONES_DATA_FAIL";
export const GET_ALERTS_ZONES_DATA_SUCCESS = "GET_ALERTS_ZONES_DATA_SUCCESS";

export const GET_ALERTS_SPECIES_DATA = "GET_ALERTS_SPECIES_DATA";
export const GET_ALERTS_SPECIES_DATA_FAIL = "GET_ALERTS_SPECIES_DATA_FAIL";
export const GET_ALERTS_SPECIES_DATA_SUCCESS =
  "GET_ALERTS_SPECIES_DATA_SUCCESS";

export const GET_ALERT_ACTIONS_TYPE = "GET_ALERT_ACTIONS_TYPE";
export const GET_ALERT_ACTIONS_TYPE_FAIL = "GET_ALERT_ACTIONS_TYPE_FAIL";
export const GET_ALERT_ACTIONS_TYPE_SUCCESS = "GET_ALERT_ACTIONS_TYPE_SUCCESS";

export const GET_MEASUREMENTS_BY_ID_DATA = "GET_MEASUREMENTS_BY_ID_DATA";
export const GET_MEASUREMENTS_BY_ID_DATA_FAIL =
  "GET_MEASUREMENTS_BY_ID_DATA_FAIL";
export const GET_MEASUREMENTS_BY_ID_DATA_SUCCESS =
  "GET_MEASUREMENTS_BY_ID_DATA_SUCCESS";
