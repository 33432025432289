import * as types from "./actionTypes";

const initialState = {
  countrysides: [],
  countryside: [],
  infoWindow: [],
  lanzas: [],
  loading: false,
  error: {
    message: "",
  },
};

const CountrysidesMapReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.CLEAR_DATA:
      state = initialState;
      break;

    case types.GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID:
      state = { ...state, loading: true };
      break;
    case types.GET_COUNTRYSIDES_MAP_DATA:
      state = { ...state, loading: true };
      break;
    case types.GET_LANZAS_BY_LOTE_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case types.GET_COUNTRYSIDES_MAP_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        countrysides: actionPayload,
      };
      break;

    case types.GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        countryside: actionPayload.countryside_map_data,
        infoWindow: actionPayload.infoWindow_map_data,
      };
      break;

    case types.GET_LANZAS_BY_LOTE_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        lanzas: actionPayload.lanzas_map_data,
      };
      break;

    case types.GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_COUNTRYSIDES_MAP_DATA_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_LANZAS_BY_LOTE_ID_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
  }

  return state;
};

export default CountrysidesMapReducer;
