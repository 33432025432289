import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { Alert } from "../../../shared/types";
import AlertCardComponent from "../alertCard/AlertCard";
import ChevronDown from "../../../assets/icons/svg/Alerts/chevron-down.svg";
import { CardComponent } from "../../card";
import EstablishmentIcon from "../../../assets/icons/svg/DashboardPage/ico-estab.svg";

interface AlertCardsComponentProps {
  alerts: Alert[];
  index?: number;
}

// Show stacked alerts up to 3 alerts
const StackedAlerts = (props: { alerts: Alert[] }) => {
  const { alerts } = props;

  return (
    <>
      {alerts.map(
        (alert: Alert, i: number) =>
          0 < i &&
          i < 3 && (
            <CardComponent
              withoutBorder
              withAlertBorder
              borderAlertColor={alert && alert.colors && alert.colors.hex}
              marginTop={"mt-0"}
              backgroundColor={"#F3F2F7"}
              className="stacked-card card-shadow"
              key={i}
              style={{
                zIndex: i,
                transform: [`translate(${6 * i}px, ${i === 1 ? 0 : 3 * i}px)`],
              }}
            ></CardComponent>
          )
      )}
    </>
  );
};

const CloseAlertsBtn = (props: {
  total: number;
  setShowAll: (show: boolean) => void;
}) => {
  const { total, setShowAll } = props;

  return (
    <div className="d-flex justify-content-center">
      <div
        className="h-100 d-flex fs-07 "
        onClick={() => setShowAll(false)}
        style={{ cursor: "pointer" }}
      >
        <div className="h-100 align-align-content-center">
          <div className="h-100 badge rounded-pill bg-dark">{total}</div>
        </div>
        <div>
          <span className="ms-1 me-1">Cerrar</span>
          <Image
            src={ChevronDown}
            className="rotate-180"
            style={{
              height: "0.5rem",
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CardShowMoreBtnComponent = (props: {
  total: number;
  setShowAll: (show: boolean) => void;
}) => {
  const { total, setShowAll } = props;

  return (
    <div className="d-flex justify-content-center">
      <div
        className="h-100 d-flex fs-07"
        onClick={() => setShowAll(true)}
        style={{ cursor: "pointer" }}
      >
        <div className="h-100 align-align-content-center">
          <div className="badge rounded-pill bg-dark">{total}</div>
        </div>
        <div>
          <span className="ms-1 me-1">Ver todas</span>
          <Image
            src={ChevronDown}
            style={{
              height: "0.5rem",
            }}
          />
        </div>
      </div>
    </div>
  );
};

const AlertCardsComponent = (props: AlertCardsComponentProps) => {
  const { alerts, index } = props;
  const [showAll, setShowAll] = useState(false);

  return (
    <div className="alert-cards-wrapper" style={{ zIndex: index }}>
      <div className={`alert-main`}>
        <div className="d-flex " style={{ flexDirection: "column" }}>
          <div className={`alert-main-title fw-bold-400`}>
            Silobolsa{" "}
            <span className={`alert-main-title-name fw-bold-600`}>
              {alerts && alerts[0] && alerts[0].silobags}
            </span>
          </div>
          <div className="d-flex flex-grow-1 mt-1 fs-09">
            <div className="h-100 d-flex justify-content-center align-items-center">
              <Image
                style={{
                  width: 14,
                  height: 14,
                }}
                src={EstablishmentIcon}
              />
            </div>
            <div className="ms-2 fs-07">
              {alerts && alerts[0] && alerts[0].countrysides}
            </div>
          </div>
        </div>

        <div>
          {showAll ? (
            <CloseAlertsBtn setShowAll={setShowAll} total={alerts.length} />
          ) : (
            alerts.length > 1 && (
              <CardShowMoreBtnComponent
                setShowAll={setShowAll}
                total={alerts.length}
              />
            )
          )}
        </div>
      </div>

      {showAll ? (
        <>
          {alerts.map((alert: Alert, i: number) => (
            <AlertCardComponent alert={alert} key={i} id={alerts.length - i} />
          ))}
        </>
      ) : (
        <div style={{ position: "relative" }}>
          <AlertCardComponent
            alert={alerts[0]}
            id={index ? index : alerts[0].alert_id}
          />
          <StackedAlerts alerts={alerts} />
        </div>
      )}
    </div>
  );
};

export default AlertCardsComponent;
