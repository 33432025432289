export enum Paths {
  ADMINISTRATOR = "/admin",
  ALERT = "/alert",
  COUNTRYSIDES_MAPS = "/countrysides",
  FORGOTPASS = "/forgot-password",
  LOGIN = "/login",
  LOGOUT = "/logout",
  NEW_SILO = "/nuevo-silo",
  NOTIFICATION = "/notification",
  NOTFOUND = "/notfound",
  ROOT = "/",
  SILO = "/silo",
  SILOBAG_MAP = "/silobag-map",
  VIEW_SILO = "/ver-silo",
  VERIFY = "/verify",
  VERIFYACCOUNT = "/verify-account",
  VERIFYRESETACCOUNT = "/verify-reset-account",
  RESET = "/reset",
  NAV = "/nav",
  NO_CONNECTION="/no-connection"
}
