import * as types from "./actionTypes";

export const clearCountrysidesDataAction = () => {
  return {
    type: types.CLEAR_DATA,
  };
};

export const getCountrysidesMapDataAction = () => {
  return {
    type: types.GET_COUNTRYSIDES_MAP_DATA,
  };
};

export const getCountrysidesMapDataFail = (error: any) => {
  return {
    type: types.GET_COUNTRYSIDES_MAP_DATA_FAIL,
    payload: error,
  };
};

export const getCountrysidesMapDataSuccess = (countrysidesMapData: any) => {
  return {
    type: types.GET_COUNTRYSIDES_MAP_DATA_SUCCESS,
    payload: countrysidesMapData,
  };
};

export const getCountrysideFromSiloByLoteIdAction = (payload: any) => {
  return {
    type: types.GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID,
    payload,
  };
};

export const getCountrysideFromSiloByLoteIdFail = (error: any) => {
  return {
    type: types.GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID_FAIL,
    payload: error,
  };
};

export const getCountrysideFromSiloByLoteIdSuccess = (fromSilo: any) => {
  return {
    type: types.GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID_SUCCESS,
    payload: fromSilo,
  };
};

export const getLanzasByLoteIdAction = (payload: any) => {
  return {
    type: types.GET_LANZAS_BY_LOTE_ID,
    payload,
  };
};

export const getLanzasByLoteIdFail = (error: any) => {
  return {
    type: types.GET_LANZAS_BY_LOTE_ID_FAIL,
    payload: error,
  };
};

export const getLanzasByLoteIdSuccess = (fromSilo: any) => {
  return {
    type: types.GET_LANZAS_BY_LOTE_ID_SUCCESS,
    payload: fromSilo,
  };
};
