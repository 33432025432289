import { all, fork } from "redux-saga/effects";
import AdministratorSaga from "./administrator/saga";
import AlertsSaga from "./alerts/saga";
import DashboardSaga from "./dashboard/saga";
import CountrysidesMapSaga from "./countrysidesMap/saga";
import OrganizationSaga from "./organization/saga";
import SiloBagSaga from "./silobag/saga";
import UserSaga from "./users/saga";
import NotificationsSaga from "./notification/saga";

export default function* rootSaga() {
  yield all([fork(DashboardSaga)]);
  yield all([fork(OrganizationSaga)]);
  yield all([fork(UserSaga)]);
  yield all([fork(CountrysidesMapSaga)]);
  yield all([fork(AlertsSaga)]);
  yield all([fork(SiloBagSaga)]);
  yield all([fork(AdministratorSaga)]);
  yield all([fork(NotificationsSaga)]);
}
