import { Alert } from "../../shared/types";
import * as types from "./actionTypes";

export const getAlertActionTypesAction = () => {
  return {
    type: types.GET_ALERT_ACTIONS_TYPE,
  };
};

export const getAlertActionTypesFail = (error: any) => {
  return {
    type: types.GET_ALERT_ACTIONS_TYPE_FAIL,
    payload: error,
  };
};

export const getAlertActionTypesSuccess = (alerts: Alert[]) => {
  return {
    type: types.GET_ALERT_ACTIONS_TYPE_SUCCESS,
    payload: alerts,
  };
};

export const getAlertsDataAction = (payload: any) => {
  return {
    type: types.GET_ALERTS_DATA,
    payload,
  };
};

export const getAlertsDataFail = (error: any) => {
  return {
    type: types.GET_ALERTS_DATA_FAIL,
    payload: error,
  };
};

export const getAlertsDataSuccess = (alerts: Alert[]) => {
  return {
    type: types.GET_ALERTS_DATA_SUCCESS,
    payload: alerts,
  };
};

export const setAlertsDisplay = (display: boolean) => {
  return {
    type: types.SET_ALERTS_DISPLAY,
    payload: display,
  };
};

export const getAlertsConfigDataAction = (payload: any) => {
  return {
    type: types.GET_ALERTS_CONFIG_DATA,
    payload,
  };
};

export const getAlertsConfigDataFail = (error: any) => {
  return {
    type: types.GET_ALERTS_CONFIG_DATA_FAIL,
    payload: error,
  };
};

export const getAlertsConfigDataSuccess = (response: any) => {
  return {
    type: types.GET_ALERTS_CONFIG_DATA_SUCCESS,
    payload: response,
  };
};

export const getAlertsTypesDataAction = () => {
  return {
    type: types.GET_ALERTS_TYPES_DATA,
  };
};

export const getAlertsTypesDataFail = (error: any) => {
  return {
    type: types.GET_ALERTS_TYPES_DATA_FAIL,
    payload: error,
  };
};

export const getAlertsTypesDataSuccess = (response: any) => {
  return {
    type: types.GET_ALERTS_TYPES_DATA_SUCCESS,
    payload: response,
  };
};

export const getAlertsZonesDataAction = () => {
  return {
    type: types.GET_ALERTS_ZONES_DATA,
  };
};

export const getAlertsZonesDataFail = (error: any) => {
  return {
    type: types.GET_ALERTS_ZONES_DATA_FAIL,
    payload: error,
  };
};

export const getAlertsZonesDataSuccess = (response: any) => {
  return {
    type: types.GET_ALERTS_ZONES_DATA_SUCCESS,
    payload: response,
  };
};

export const getAlertsSpeciesDataAction = () => {
  return {
    type: types.GET_ALERTS_SPECIES_DATA,
  };
};

export const getAlertsSpeciesDataFail = (error: any) => {
  return {
    type: types.GET_ALERTS_SPECIES_DATA_FAIL,
    payload: error,
  };
};

export const getAlertsSpeciesDataSuccess = (response: any) => {
  return {
    type: types.GET_ALERTS_SPECIES_DATA_SUCCESS,
    payload: response,
  };
};

export const getMeasurementsByIdAction = (measurementIds: any) => {
  return {
    type: types.GET_MEASUREMENTS_BY_ID_DATA,
    measurementIds,
  };
};

export const getMeasurementsByIdFail = (error: any) => {
  return {
    type: types.GET_MEASUREMENTS_BY_ID_DATA_FAIL,
    payload: error,
  };
};

export const getMeasurementsByIdSuccess = (response: any) => {
  return {
    type: types.GET_MEASUREMENTS_BY_ID_DATA_SUCCESS,
    payload: response,
  };
};
