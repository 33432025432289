import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_ALL_AVAILABLE_DEVICE_ACTION,
  GET_COUNTRYSIDES,
  GET_DEVICE_ACTION_TYPE_ACTION,
  GET_DEVICE_TO_ATTACH_ACTION,
  GET_HARVEST,
  GET_LOT,
  GET_PRODUCTS,
  GET_SILOBAG,
  GET_SPECIES,
  UPDATE_SILOBAGS_FILTERED_LIST,
} from "./actionTypes";

import { ApiResponse } from "../../shared/types";
import { apiGet } from "../../shared/ApiService";
import { API_URL_IOF, API_WOZALABS_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";

import {
  getAllAvailableDeviceForOrganizationFail,
  getAllAvailableDeviceForOrganizationSuccess,
  getAllCountrysidesFail,
  getAllCountrysidesSuccess,
  getAllHarvestFail,
  getAllHarvestSuccess,
  getAllLoteFail,
  getAllLoteSuccess,
  getAllProductsFail,
  getAllProductsSuccess,
  getAllSiloBagFail,
  getAllSiloBagSuccess,
  getAllSpeciesFail,
  getAllSpeciesSuccess,
  getDeviceActionTypeFail,
  getDeviceActionTypeSuccess,
  getDeviceToAttachFileFail,
  getDeviceToAttachFileSuccess,
  updateSiloBagFilteredFailed,
  updateSiloBagFilteredSuccess,
} from "./actions";

function* onGetCountrysides(payload: any) {
  try {
    const { organization_id } = payload;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.COUNTRYSIDES}`,
        })
      );
    });
    yield put(
      getAllCountrysidesSuccess({
        organization_id: organization_id,
        response: response,
      })
    );
  } catch (error: any) {
    yield put(getAllCountrysidesFail(error.response));
  }
}

function* onGetHarvests(payload: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.HARVESTS}`,
        })
      );
    });
    yield put(getAllHarvestSuccess(response));
  } catch (error: any) {
    yield put(getAllHarvestFail(error.response));
  }
}

function* onGetProducts(payload: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.SILO_PRODUCT}`,
        })
      );
    });
    yield put(getAllProductsSuccess(response));
  } catch (error: any) {
    yield put(getAllProductsFail(error.response));
  }
}

function* onGetSpecies(payload: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.SILO_SPECIES}`,
        })
      );
    });
    yield put(getAllSpeciesSuccess(response));
  } catch (error: any) {
    yield put(getAllSpeciesFail(error.response));
  }
}

function* onGetSilobags() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.SILOBAGS}`,
        })
      );
    });
    yield put(getAllSiloBagSuccess(response));
  } catch (error: any) {
    yield put(getAllSiloBagFail(error.response));
  }
}

function* onGetAvailableDevices(payload: any) {
  try {
    const { orgId } = payload;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.FREE_DEVICES}`,
        })
      );
    });
    yield put(getAllAvailableDeviceForOrganizationSuccess(response));
  } catch (error: any) {
    yield put(getAllAvailableDeviceForOrganizationFail(error.response));
  }
}

function* onChangeSiloBagFilters(payloads: any) {
  const { payload } = payloads;
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(updateSiloBagFilteredSuccess(payload));
  } catch (error: any) {
    yield put(updateSiloBagFilteredFailed(error.response));
  }
}

function* onGetDeviceToAttachFile() {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.DEVICES.GET_DEVICE_TO_ATTACH_FILE}`,
        })
      );
    });
    yield put(getDeviceToAttachFileSuccess(response && response[0]));
  } catch (error: any) {
    yield put(getDeviceToAttachFileFail(error.response));
  }
}

function* onGetDeviceActionType() {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.DEVICES.GET_DEVICE_ACTION_TYPE}`,
        })
      );
    });
    yield put(getDeviceActionTypeSuccess(response && response[0]));
  } catch (error: any) {
    yield put(getDeviceActionTypeFail(error.response));
  }
}

function* SiloBagSaga() {
  yield takeLatest(GET_ALL_AVAILABLE_DEVICE_ACTION, onGetAvailableDevices);
  yield takeLatest(GET_COUNTRYSIDES, onGetCountrysides);
  yield takeLatest(GET_HARVEST, onGetHarvests);
  yield takeLatest(GET_PRODUCTS, onGetProducts);
  yield takeLatest(GET_SPECIES, onGetSpecies);
  yield takeLatest(GET_SILOBAG, onGetSilobags);
  yield takeLatest(UPDATE_SILOBAGS_FILTERED_LIST, onChangeSiloBagFilters);
  yield takeLatest(GET_DEVICE_TO_ATTACH_ACTION, onGetDeviceToAttachFile);
  yield takeLatest(GET_DEVICE_ACTION_TYPE_ACTION, onGetDeviceActionType);
}

export default SiloBagSaga;
