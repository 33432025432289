import React from "react";
import { Spinner } from "react-bootstrap";

const LoadableLoadingComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Spinner
        animation="border"
        role="status"
        style={{ position: "inherit" }}
      ></Spinner>
      <h3 style={{ marginTop: 10 }}>Cargando</h3>
    </div>
  );
};
export default LoadableLoadingComponent;
