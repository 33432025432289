export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const CLEAN_USER = "CLEAN_USER";
export const CLEAN_USER_FAIL = "CLEAN_USER_FAIL";
export const CLEAN_USER_SUCCESS = "CLEAN_USER_SUCCESS";

export const VERIFY_USER_ACTION = "VERIFY_USER_ACTION";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";

export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const GET_USER_PERMISSIONS_FAIL = "GET_USER_PERMISSIONS_FAIL";
export const GET_USER_PERMISSIONS_SUCCESS = "GET_USER_PERMISSIONS_SUCCESS";
