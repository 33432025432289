import * as types from "./actionTypes";

export const getAllSiloBagAction = () => {
  return {
    type: types.GET_SILOBAG,
  };
};

export const getAllSiloBagFail = (error: any) => {
  return {
    type: types.GET_SILOBAG_FAIL,
    payload: error,
  };
};

export const getAllSiloBagSuccess = (silobags: any) => {
  return {
    type: types.GET_SILOBAG_SUCCESS,
    payload: silobags,
  };
};

export const updateSiloBagFilteredAction = (silobags: any) => {
  return {
    type: types.UPDATE_SILOBAGS_FILTERED_LIST,
    payload: silobags,
  };
};

export const updateSiloBagFilteredSuccess = (silobags: any) => {
  return {
    type: types.UPDATE_SILOBAGS_FILTERED_LIST_SUCCESS,
    payload: silobags,
  };
};

export const updateSiloBagFilteredFailed = (silobags: any) => {
  return {
    type: types.UPDATE_SILOBAGS_FILTERED_LIST_FAIL,
    payload: silobags,
  };
};

export const getAllCountrysidesAction = (organization_id: number) => {
  return {
    type: types.GET_COUNTRYSIDES,
    organization_id,
  };
};

export const getAllCountrysidesFail = (error: any) => {
  return {
    type: types.GET_COUNTRYSIDES_FAIL,
    payload: error,
  };
};

export const getAllCountrysidesSuccess = (establishment: any) => {
  return {
    type: types.GET_COUNTRYSIDES_SUCCESS,
    payload: establishment,
  };
};

export const getAllLoteAction = (establishmentId: number) => {
  return {
    type: types.GET_LOT,
    establishmentId,
  };
};

export const getAllLoteFail = (error: any) => {
  return {
    type: types.GET_LOT_FAIL,
    payload: error,
  };
};

export const getAllLoteSuccess = (lot: any) => {
  return {
    type: types.GET_LOT_SUCCESS,
    payload: lot,
  };
};

export const getAllProductsAction = () => {
  return {
    type: types.GET_PRODUCTS,
  };
};

export const getAllProductsFail = (error: any) => {
  return {
    type: types.GET_PRODUCTS_FAIL,
    payload: error,
  };
};

export const getAllProductsSuccess = (prod: any) => {
  return {
    type: types.GET_PRODUCTS_SUCCESS,
    payload: prod,
  };
};

export const getAllSpeciesAction = () => {
  return {
    type: types.GET_SPECIES,
  };
};

export const getAllSpeciesFail = (error: any) => {
  return {
    type: types.GET_SPECIES_FAIL,
    payload: error,
  };
};

export const getAllSpeciesSuccess = (species: any) => {
  return {
    type: types.GET_SPECIES_SUCCESS,
    payload: species,
  };
};

export const getAllHarvestAction = () => {
  return {
    type: types.GET_HARVEST,
  };
};

export const getAllHarvestFail = (error: any) => {
  return {
    type: types.GET_HARVEST_FAIL,
    payload: error,
  };
};

export const getAllHarvestSuccess = (harvest: any) => {
  return {
    type: types.GET_HARVEST_SUCCESS,
    payload: harvest,
  };
};

export const getAllAvailableDeviceForOrganizationAction = (orgId: number) => {
  return {
    type: types.GET_ALL_AVAILABLE_DEVICE_ACTION,
    orgId,
  };
};

export const getAllAvailableDeviceForOrganizationFail = (error: any) => {
  return {
    type: types.GET_ALL_AVAILABLE_DEVICE_FAIL,
    payload: error,
  };
};

export const getAllAvailableDeviceForOrganizationSuccess = (
  establishment: any
) => {
  return {
    type: types.GET_ALL_AVAILABLE_DEVICE_SUCCESS,
    payload: establishment,
  };
};

export const getDeviceToAttachFileAction = () => {
  return {
    type: types.GET_DEVICE_TO_ATTACH_ACTION,
  };
};

export const getDeviceToAttachFileFail = (error: any) => {
  return {
    type: types.GET_DEVICE_TO_ATTACH_FAIL,
    payload: error,
  };
};

export const getDeviceToAttachFileSuccess = (deviceToAttach: any) => {
  return {
    type: types.GET_DEVICE_TO_ATTACH_SUCCESS,
    payload: deviceToAttach,
  };
};

export const getDeviceActionTypeAction = () => {
  return {
    type: types.GET_DEVICE_ACTION_TYPE_ACTION,
  };
};

export const getDeviceActionTypeFail = (error: any) => {
  return {
    type: types.GET_DEVICE_ACTION_TYPE_FAIL,
    payload: error,
  };
};

export const getDeviceActionTypeSuccess = (deviceToAttach: any) => {
  return {
    type: types.GET_DEVICE_ACTION_TYPE_SUCCESS,
    payload: deviceToAttach,
  };
};
