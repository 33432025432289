import React, { useEffect, useState } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NotIconOn from "../../../assets/icons/svg/DashboardPage/ico-alerts-on.svg";
import NotIconOff from "../../../assets/icons/svg/DashboardPage/ico-alerts-off.svg";
import {
  getCurrentAlert,
  saveUserLastAlarmNotification,
} from "../../../core/auth/AuthService";
import { Alert, ReduxType } from "../../../shared/types";
import {
  getAlertActionTypesAction,
  getAlertsDataAction,
  getMeasurementsByIdAction,
  setAlertsDisplay,
} from "../../../store/alerts/actions";
import { AlertCardsComponent } from "../alertCards";
import { groupAlertsAndSort } from "../../../shared/helper";

const AlertsDrawerComponent = (props: any) => {
  const [displayAlerts, setDisplayAlerts] = useState<Alert[][]>([]);
  const dispatch = useDispatch();

  const { style } = props;

  const {
    orgData,
    metricsData: { metrics, display },
  } = useSelector((state: ReduxType) => {
    return state;
  });

  const handleClose = () => dispatch(setAlertsDisplay(!display));

  const { metricsData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (orgData && orgData.currentOrganization) {
      doAlertsRequest();
    }
  }, [orgData.currentOrganization]);

  useEffect(() => {
    if (metricsData.metrics) {
      doMeasurementRequest();
    }
  }, [metricsData.metrics]);

  useEffect(() => {
    if (
      !metricsData.metricActionsTypes ||
      (metricsData.metricActionsTypes &&
        metricsData.metricActionsTypes.length == 0 &&
        !metricsData.loading)
    ) {
      getAlertTypes();
    }
  }, [metricsData.metricActionsTypes]);

  const getAlertTypes = async () => {
    await dispatch(getAlertActionTypesAction());
  };

  const doAlertsRequest = async () => {
    await dispatch(
      getAlertsDataAction({
        user_id: orgData.currentOrganization.user_id,
        org_id: orgData.currentOrganization.id,
      })
    );
  };

  const doMeasurementRequest = async () => {
    const ids =
      metricsData.metrics &&
      metricsData.metrics.map(({ measurement_id }: any) => measurement_id);
    const removeDuplicated = new Set(ids);
    const finalArray = Array.from(removeDuplicated).toString();
    if (ids) {
      await dispatch(getMeasurementsByIdAction(finalArray));
    }
  };

  useEffect(() => {
    if (metrics && metrics.length > 0) {
      const sorted = groupAlertsAndSort(metrics);
      setDisplayAlerts(sorted);
    }
  }, [metrics]);

  const verifyAlertIcon = () => {
    if (displayAlerts && displayAlerts[0] && displayAlerts[0][0]) {
      const firstAlert = displayAlerts[0][0].id;
      if (getCurrentAlert() !== firstAlert.toString()) {
        return NotIconOn;
      }
    }
    return NotIconOff;
  };

  const alertsToggle = () => {
    if (displayAlerts && displayAlerts[0] && displayAlerts[0][0]) {
      saveUserLastAlarmNotification(displayAlerts[0][0].id);
    }
    dispatch(setAlertsDisplay(!display));
  };

  return (
    <>
      <Nav.Link style={style ? { ...style } : {}} onClick={alertsToggle}>
        <img
          src={verifyAlertIcon()}
          className="d-inline-block align-top dashboard-alarm"
          alt="React Bootstrap logo"
        />
      </Nav.Link>
      <Offcanvas
        show={display}
        onHide={handleClose}
        placement="end"
        backdropClassName={"offcanvas-backdrop-custom"}
        className={"dashboard-canvas-alerts"}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className={`fw-bold-600`}>Alertas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-1">
          <div>
            {displayAlerts.map((alerts: Alert[], index: number) => (
              <AlertCardsComponent
                alerts={alerts}
                index={displayAlerts.length - index}
                key={index}
              />
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AlertsDrawerComponent;
