import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import iofLogo from "../../../assets/img/Dashboard/dashboardLogo.png";

const SiloBagPdfComponent = (props: any) => { 

  const { silobag, userProfile } = props;

  const getConectedDevices = () => {

    let positions = [];

    for(let i = 0; i < 5; i++)
    {
      if(silobag.silobags_devices)
      {
        const code = silobag.silobags_devices[i] ? silobag.silobags_devices[i]?.devices?.name : "-";
        positions.push(code);
      }
      else{
        positions.push("-")
      }
    }

    return positions;
  };
      // Create styles
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 35,
      paddingHorizontal:35,
      fontSize: 10,
      fontFamily: 'Helvetica'
    },
    flexContainer : {
      display: 'flex', 
      flexDirection: 'row'      
    },
    flexItem: {
      margin: 5,
      padding: 5,
      flexGrow:1,
      flexBasis: '50%'
    },
    spaceBetween : {
      justifyContent: 'space-between'
    },
    spaceArroud : {
      justifyContent: 'space-around'
    },
    logo: {
      width:75,
      height:40
    },
    titleHeader: {
      fontSize: 20,
      marginTop: 10.72,
      marginBottom: 10.72,
      marginLeft: 10,
      marginRight: 10,
      fontWeight: 'bold'
    },
    subTitle: {
      fontSize: 15,
      marginTop: 10.72,
      marginBottom: 10.72,
      paddingBottom: 2,
      fontWeight: 'bold',
      borderBottom: "solid",
      borderBottomColor: "#d1e0b7",
      borderBottomWidth: "2"
    },
    photoSubtitle: {
      fontSize: 13,
      color:"white",
      backgroundColor: "#7aa732",
      margin: 5,
      padding: 5,
      maxWidth:135,
      borderRadius: "25%"
    },
    photoGalleryElement: {
      border:"solid", 
      borderColor:"white", 
      borderWidth: 3,
      borderRadius: "10%"
    },
    lightGreenBox: {
      backgroundColor: "#e3edd4",
      borderRadius: "10%",
      marginVertical: 2,
      padding: 5
    },
    smallLightGreenBox: {
      backgroundColor: "#d6e9b7",
      borderRadius: "10%",
      marginVertical: 1,
      padding: 5
    },
    darkGreenBox : {
      fontSize:14,
      textAlign:"center",
      color:"white",
      backgroundColor: "#36620d",
      paddingHorizontal: 10,
      paddingVertical: 5,
      margin: 5,
      borderRadius: "10%"
    },
    lightGreenDarkBorderBox : {
      fontSize:14,
      textAlign:"center",
      color:"black",
      backgroundColor: "#ebf0e3",
      paddingHorizontal: 10,
      paddingVertical: 5,
      margin: 5,
      borderRadius: "10%",
      border:"solid", 
      borderColor:"#36620d", 
      borderWidth: 1,
    },
    qrBox: {
      backgroundColor: "#e3edd4",
      borderRadius: "5%",
      padding: 10
    },
    imgResponsive: {
      width: "100%"
    },
    paragraph : {
      marginTop: 7,
      marginBottom: 7,
      marginLeft: 5,
      marginRight: 5
    },
    boldText : {
      fontFamily: 'Helvetica-Bold',
      fontWeight: "light"
    },
    bgLightGreen: {
      backgroundColor: "#e3edd4"
    },
    bgVividLightGreen:{
      backgroundColor: "#d6e9b7"
    },
    mt2:{
      marginTop: 2
    },
    mt3:{
      marginTop: 3
    },
    mt5:{
      marginTop: 5
    },
    mb2:{
      marginBottom:2
    },
    mb3:{
      marginBottom:3
    },
    mb5:{
      marginBottom:5
    },
    pt2:{
      paddingTop:2
    },
    pb2:{
      paddingBottom:2
    }
    });

    const getHeader = () => {
      return(
        <View
            style={[
              styles.flexContainer,
              styles.spaceBetween
            ]}>
              <Image source={iofLogo} style={styles.logo}></Image>
              <Text style={styles.titleHeader}>Certificado de Mercaderia en Campo</Text>
          </View>
      );
    };

    return (
      <Document>
        <Page size="A4" style={styles.body}>
          {getHeader()}
          {/* Datos de la Organización */}
          <View>
            <Text style={styles.subTitle}>
              Datos de la Organización
            </Text>
            <View style={styles.flexContainer}>
              <View style={styles.flexItem}>
                <View 
                  style={[styles.flexContainer, styles.spaceBetween, styles.lightGreenBox]}
                >
                  <Text> Nombre Establecimiento </Text>

                  <Text style={styles.boldText}>
                    { silobag.lots.countrysides.name }
                  </Text>
                </View>

                <View 
                  style={[styles.flexContainer, styles.spaceBetween, styles.lightGreenBox]}
                >
                  <Text> Razon Social </Text>
                  <Text style={styles.boldText}>
                    {silobag.lots.countrysides.companies.legal_name}
                  </Text>
                </View>
                <View 
                  style={[styles.flexContainer,styles.spaceBetween, styles.lightGreenBox]}
                >
                  <Text> C.U.I.T </Text>
                  <Text style={styles.boldText}>
                    {silobag.lots.countrysides.companies.tax_id}
                  </Text>
                </View>
                <View 
                  style={[styles.flexContainer,styles.spaceBetween, styles.lightGreenBox]}
                >
                  <Text> Coordenadas </Text>
                  <View style={styles.boldText}>
                    <Text> { silobag.main_lat } </Text>
                    <Text> { silobag.main_lon } </Text>
                  </View>
                </View>
              </View>
              <View style={styles.flexItem}>
                <View 
                  style={[styles.flexContainer,styles.spaceBetween, styles.lightGreenBox]}
                >
                  <View>
                    <Text style={[styles.mt2,styles.mb2,styles.pt2,styles.pb2]}> Responsable Silobolsa </Text>
                    <Text style={[styles.smallLightGreenBox]}>
                      <Text style={[styles.boldText, ]}>Teléfono:</Text> { userProfile.phone } 
                    </Text>
                  </View>
                  <View style={styles.boldText}>
                    <Text style={[styles.mt2,styles.mb2,styles.pt2,styles.pb2]}> { userProfile.fullname } </Text>                    
                  </View>
                </View>
                <View 
                  style={[styles.flexContainer,styles.spaceBetween, styles.lightGreenBox]}
                >
                  <View>
                    <Text style={[styles.mt2,styles.pt2]}> Responsable Armado  </Text>
                    <Text> del Silobolsa </Text>
                    <Text style={[styles.smallLightGreenBox]}>
                      <Text style={[styles.boldText, ]}>Teléfono:</Text> { userProfile.phone } 
                    </Text>
                  </View>
                  <View style={styles.boldText}>
                    <Text style={[styles.mt2,styles.mb2,styles.pt2,styles.pb2]}> { userProfile.fullname } </Text>                    
                  </View>
                </View>
              </View>              
            </View>
            
          </View>
          {/* FIN Datos de la Organización */}


          {/* Datos de Silobolsa */}
          <View>
            <Text style={styles.subTitle}>Datos del Silobolsa</Text>
            <View style={styles.flexContainer}>
              <View style={styles.flexItem}>
                <View 
                  style={[styles.flexContainer, styles.spaceBetween, styles.lightGreenBox]}
                >
                  <Text> Nombre SB </Text>
                  <Text style={styles.boldText}>
                    {silobag.name}
                  </Text>
                </View>
                <View 
                  style={[styles.lightGreenBox]}
                >
                  <Text> ID Unico Silo Bolsa </Text>
                  <Text style={[styles.smallLightGreenBox, styles.boldText,{fontSize:8.1}]}>
                    {silobag.uuid}
                  </Text>
                </View>
                <View 
                  style={[styles.flexContainer, styles.spaceBetween, styles.lightGreenBox]}
                >
                  <Text> Periodo de Cosecha </Text>
                  <Text style={styles.boldText}>
                    {silobag.silobag_harvests.name}
                  </Text>
                </View>
                <View 
                  style={[styles.flexContainer, styles.spaceBetween, styles.lightGreenBox]}
                >
                  <Text> Fecha de Confección </Text>
                  <Text style={styles.boldText}>
                    {silobag.confection_date || "-"}
                  </Text>
                </View>
                <View 
                  style={[styles.flexContainer,styles.spaceBetween, styles.lightGreenBox,{alignItems:"center"}]}
                >
                  <Text> Coordenadas </Text>
                  <View style={styles.boldText}>
                    <Text> { silobag.main_lat || "-" } </Text>
                    <Text> { silobag.main_lon || "-" } </Text>
                  </View>
                </View>
                <View 
                  style={[styles.flexContainer,styles.spaceBetween, styles.lightGreenBox]}
                >
                  <View>
                    <Text style={[styles.mt2,styles.mb2,styles.pt2,styles.pb2]}> Largo </Text>
                    <Text style={[styles.smallLightGreenBox]}>
                      <Text style={[styles.boldText, ]}>Fuente:</Text> Medición manual 
                    </Text>
                  </View>
                  <View style={styles.boldText}>
                    <Text style={[styles.mt2,styles.mb2,styles.pt2,styles.pb2]}> {silobag.size || "-"} metros</Text>                    
                  </View>
                </View>
              </View>
              <View style={styles.flexItem}>
                <View 
                  style={[styles.flexContainer, styles.spaceBetween, styles.lightGreenBox]}
                >
                  <Text> Lote Cosecha (Origen de granos) </Text>
                  <Text style={styles.boldText}>
                    - {/* No poseemos este dato aun*/}
                  </Text>
                </View>
                <View 
                  style={[styles.flexContainer, styles.spaceBetween, styles.lightGreenBox]}
                >
                  <Text> Lote Silobolsa </Text>
                  <Text style={styles.boldText}>
                    { silobag.lots?.name || "-" } 
                  </Text>
                </View>
                <View 
                  style={[styles.flexContainer, styles.spaceBetween, styles.lightGreenBox]}
                >
                  <Text> Especie </Text>
                  <Text style={styles.boldText}>
                    {silobag.silobag_species_types?.name || "-"} ({silobag.silobag_product_types?.name || "-"})
                  </Text>
                </View>
                <View 
                  style={[styles.flexContainer,styles.spaceBetween, styles.lightGreenBox]}
                >
                  <View>
                    <Text style={[styles.mt2,styles.mb2,styles.pt2,styles.pb2]}> Peso de Embolsado (Kg) </Text>
                    <Text style={[styles.smallLightGreenBox]}>
                      <Text style={[styles.boldText, ]}>Fuente:</Text> Balanza digital cosechadora  
                    </Text>
                  </View>
                  <View style={styles.boldText}>
                    <Text style={[styles.mt2,styles.mb2,styles.pt2,styles.pb2]}> { silobag.calculated_weight || "-"} </Text>                    
                  </View>
                </View>
                <View 
                  style={[styles.flexContainer,styles.spaceBetween, styles.lightGreenBox]}
                >
                  <View>
                    <Text style={[styles.mt2,styles.mb2,styles.pt2,styles.pb2]}> Humedad de Embolsado </Text>
                    <Text style={[styles.smallLightGreenBox]}>
                      <Text style={[styles.boldText, ]}>Fuente:</Text> Medición con dispositivo X
                    </Text>
                  </View>
                  <View style={styles.boldText}>
                    <Text style={[styles.mt2,styles.mb2,styles.pt2,styles.pb2]}> { silobag.initial_humidity || "0"}%</Text>                    
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View debug={false} style={[styles.flexContainer]}>
            <View debug={false} style={[styles.flexItem, {marginLeft:0,marginRight:0}]}>
              <Text style={styles.subTitle}>Dispositivos Conectados - <Text style={{fontSize:12}}> Histórico </Text></Text>
              {
                getConectedDevices().map(( deviceName, index)=>{
                  return(
                    <View key={"device-name"+index} style={styles.flexContainer}>
                      <View style={[styles.flexItem, styles.darkGreenBox]}>
                        <Text>Posición {index+1}</Text>
                      </View>
                      <View style={[styles.flexItem,styles.lightGreenDarkBorderBox]}>
                        <Text>{deviceName}</Text>
                      </View>
                    </View>
                  );
                })
              }
            </View>
            <View style={[styles.flexItem,{marginLeft:0,marginRight:0}]}>
              <Text style={styles.subTitle}>Silobolsa  en la plataforma</Text>
              <Text style={{
                padding:10,
                fontSize:14,
                lineHeight:1.5
              }}>
                Escaneando el <Text style={styles.boldText}>QR</Text> accederás a la vista del silobolsa en la plataforma.
              </Text>
              <View style={[styles.flexContainer,styles.spaceBetween,styles.qrBox,{alignItems:"center"}]}>
                <View>
                  <Text style={[styles.boldText,{color:"#7aa732", fontSize:12, marginBottom:5}]}>ESCANEAR</Text>
                  <Text style={[styles.boldText,{color:"#7aa732", fontSize:12}]}>QR {'------->'} </Text>
                </View>
                <View style={{
                  width:100,
                  height:100,
                  backgroundColor:"#7aa732"
                }}></View>
              </View>
            </View>
          </View>
          <View break>
            {getHeader()}
            <Text style={styles.subTitle}>Fotografías de la instalación</Text>

            <View style={[styles.bgLightGreen, styles.mt5,styles.mb5,styles.pt2,styles.pb2,{borderRadius:"5%"}]}>
              <Text style={[styles.photoSubtitle]}>Medición de Silobolsa</Text>
              <View style={[styles.flexContainer, {paddingHorizontal:10, paddingBottom: 9}]}>
                <View style={[styles.flexItem,styles.photoGalleryElement,{padding:0}]}>
                  {
                    silobag.init_image ?
                    <Image style={[styles.imgResponsive,{borderRadius:"9.5%"}]} src={silobag.init_image}></Image> 
                    :
                    <View
                      style={[
                        styles.darkGreenBox,
                        {
                          fontSize:10
                        }
                      ]}
                    >
                      <Text>Comienzo de silobolsa</Text>
                      <Text>Imagen sin cargar</Text>
                    </View>
                  }                  
                </View>
                <View style={[styles.flexItem,styles.photoGalleryElement,{padding:0}]}>
                {
                    silobag.main_image ?
                    <Image style={[styles.imgResponsive,{borderRadius:"9.5%"}]} src={silobag.main_image}></Image> 
                    :
                    <View
                      style={[
                        styles.darkGreenBox,                        
                        {
                          fontSize:10
                        }
                      ]}
                    >
                      <Text>Silobolsa</Text>
                      <Text>Imagen sin cargar</Text>
                    </View>
                }
                </View>
                <View style={[styles.flexItem,styles.photoGalleryElement,{padding:0}]}>
                {
                    silobag.end_image ?
                    <Image style={[styles.imgResponsive,{borderRadius:"9.5%"}]} src={silobag.end_image}></Image> 
                    :
                    <View
                      style={[
                        styles.darkGreenBox,                        
                        {
                          fontSize:10
                        }
                      ]}
                    >
                      <Text>Fin de silobolsa</Text>
                      <Text>Imagen sin cargar</Text>
                    </View>
                  }
                </View>
              </View>
            </View>

            <View style={[styles.bgLightGreen,styles.mt5,styles.mb5,styles.pt2,styles.pb2,{borderRadius:"5%"}]}>
              <Text style={[styles.photoSubtitle]}>Lanzas de Silobolsa</Text>
              <View style={[styles.flexContainer, {paddingHorizontal:10, paddingBottom: 9, flexWrap:"wrap"}]}>
                {
                  silobag.silobags_devices?.length > 0 ?
                  silobag.silobags_devices.map((device:any,index:number)=>{
                    return(
                      <View key={"devices-images"+index} style={[styles.flexItem,styles.photoGalleryElement,{maxWidth:158,padding:0}]}>
                        {
                          device.image ?
                          <Image style={[styles.imgResponsive,{borderRadius:"9.5%"}]} src={device.image}></Image>
                          :
                          <View
                            style={[
                              styles.darkGreenBox,
                              {
                                fontSize:10
                              }
                            ]}
                          >
                            <Text style={styles.boldText}>{device.devices.name}</Text>
                            <Text>Imagen sin cargar</Text>
                          </View>
                        }
                      </View>
                    );
                  })
                  :
                  <View
                    style={[
                      styles.darkGreenBox
                    ]}
                  >
                    <Text>Sin imágenes cargadas</Text>
                  </View>
                }
              </View>
            </View>
          </View>
        </Page>
      </Document>);
};

export default SiloBagPdfComponent;