import format from "date-fns/format";
import React, { ReactNode, useEffect, useState } from "react";
import { Badge, Form, Modal, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAlertActionInfo } from "../../../shared/helper";
import { ReduxType } from "../../../shared/types";
import {
  getAlertActionTypesAction,
  getAlertsDataAction,
} from "../../../store/alerts/actions";
import { CardComponent } from "../../card";

import { InputTextAreaComponent } from "../../inputs";
import { CustomDropdown } from "./customDropdown";
import _ from "lodash";
import { apiPatch, apiPost, apiPut } from "../../../shared/ApiService";
import { API_URL_IOF, API_WOZALABS_URL } from "../../../config/general-config";
import { Endpoints } from "../../../config/endpoints";

interface AlertCardComponentProps {
  alert: any;
  id: number;
  action?: ReactNode;
  handleClose?: any;
}

const AlertCardComponent = (props: AlertCardComponentProps) => {
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState();

  const [lastAlert, setLastAlert] = useState<any>();

  const [alertStatus, setAlertStatus] = useState<any>();
  const [resetData, setResetData] = useState<boolean>(false);

  const [alertTypes, setAlertTypes] = useState<any[]>([]);
  const { alert, action, id, handleClose } = props;

  const dispatch = useDispatch();

  const { metricsData, orgData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (lastAlert && lastAlert.description) {
      setDescription(lastAlert.description);
    }
  }, [lastAlert]);

  useEffect(() => {
    if (
      alert &&
      alert.alert_actions &&
      alert.alert_actions[alert.alert_actions.length - 1]
    ) {
      setLastAlert(
        alert &&
          alert.alert_actions &&
          alert.alert_actions[alert.alert_actions.length - 1]
      );
    }
  }, [alert]);

  useEffect(() => {
    if (
      !metricsData.metricActionsTypes ||
      (metricsData.metricActionsTypes &&
        metricsData.metricActionsTypes.length == 0 &&
        !metricsData.loading)
    ) {
      getAlertTypes();
    }
  }, [metricsData.metricActionsTypes]);

  const getAlertTypes = async () => {
    await dispatch(getAlertActionTypesAction());
  };

  useEffect(() => {
    if (
      metricsData.metricActionsTypes &&
      metricsData.metricActionsTypes.length > 0
    ) {
      generateAlertTypes();
    }
  }, [metricsData.metricActionsTypes]);

  const generateAlertTypes = () => {
    const at: any[] = [];
    metricsData.metricActionsTypes.map((e) => {
      at.push({
        ...getAlertActionInfo(e.name),
        id: e.id,
        label: e.name,
      });
    });
    setAlertTypes(at);
  };

  const submitState = async (e: any) => {
    try {
      e.preventDefault();
      const date = new Date();

      const reminderDate = alertStatus.reminder
        ? new Date(date.setDate(date.getDate() + alertStatus.reminder))
        : null;

      const bodyContent: any = {
        alert_id: alert.id,
        user_id: orgData.currentOrganization.user_id,
        alert_actions_type_id: alertStatus.alert_actions_type_id,
        action_date: new Date(),
        description: description,
      };

      if (reminderDate) {
        bodyContent["days_until_next_action"] = alertStatus.reminder;
      }

      if (
        lastAlert &&
        lastAlert.alert_actions_type_id == bodyContent.alert_actions_type_id
      ) {
        await apiPut({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.ALERT_ACTIONS}/${lastAlert.id}`,
          body: bodyContent,
        });
      } else {
        await apiPost({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.ALERT_ACTIONS}`,
          body: bodyContent,
        });
        setDescription(undefined);
      }
      closeModal(false);
      handleClose && handleClose();
      doAlertsRequest();
    } catch (error) {
      console.error(error);
    }
  };

  const doAlertsRequest = async () => {
    if (orgData && orgData.currentOrganization) {
      await dispatch(
        getAlertsDataAction({
          user_id: orgData.currentOrganization.user_id,
          org_id: orgData.currentOrganization.id,
        })
      );
    }
  };

  const closeModal = (fromCancel: boolean) => {
    if (fromCancel) {
      setResetData(true);
    }
    setShowModal(false);
  };

  return (
    <CardComponent
      withoutBorder
      withAlertBorder
      borderAlertColor={alert && alert.colors && alert.colors.hex}
      minHeight={120}
      height={"auto"}
      marginTop={"mt-3"}
      backgroundColor={"#F3F2F7"}
      style={{
        zIndex: `${id + 1000} `,
      }}
    >
      <Stack style={{ rowGap: 7, marginTop: "0.35rem" }}>
        <div className="h-100 d-flex align-items-center fs-07">
          <div className="flex-grow-1 fw-bold-500">
            {alert.measurement_created_at &&
              format(
                new Date(alert.measurement_created_at),
                "dd/MM/yyyy, HH:mm"
              )}
          </div>
          <div className="me-2">
            <span className="card-muted me-1">Valor alerta</span>
            <span className="fs-08 fw-bold-500 text-reset ">
              {alert && alert.measurement_value
                ? parseFloat(alert.measurement_value).toFixed(2) + "%"
                : "-"}
            </span>
          </div>
          <Badge
            pill
            bg="custom"
            className="fs-08"
            style={{
              backgroundColor: alert && alert.colors && alert.colors.hex,
              color: "#FFFFFF",
            }}
          >
            {(alert && alert.metrics && alert.metrics.short_name) || "-"}
          </Badge>
        </div>
        {alert.devices && (
          <div
            className="d-inline-flex f-wrap align-center"
            style={{ columnGap: 5 }}
          >
            <span className="fs-07 card-muted">Dispositivo </span>
            <span className="fs-10 fw-bold-500">{alert.devices}</span>
          </div>
        )}
        <div
          className="d-inline-flex f-wrap align-center"
          style={{ columnGap: 5 }}
        >
          <span className="fs-07 card-muted">Estado </span>
          <div style={{ width: "50%" }}>
            <CustomDropdown
              id={id}
              resetData={resetData}
              setResetData={setResetData}
              lastActionData={lastAlert}
              alertOptionTypes={alertTypes}
              onSelect={(alertStatusId: any, showModalAt: boolean) => {
                if (showModalAt) {
                  setShowModal(!showModal);
                }
                if (
                  alertStatusId &&
                  alertStatusId.alert_actions_type_id &&
                  lastAlert &&
                  lastAlert.alert_actions_type_id &&
                  alertStatusId.alert_actions_type_id !=
                    lastAlert.alert_actions_type_id
                ) {
                  setDescription(undefined);
                } else {
                  setDescription(lastAlert && lastAlert.description);
                }
                setAlertStatus(alertStatusId);
              }}
            />
          </div>
        </div>
        {action}
      </Stack>
      <Modal
        onClose={() => setShowModal(false)}
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ overflow: "auto", maxHeight: 750 }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Cambio de estado
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            className="modal-form"
            onSubmit={submitState}
            id="modal-form"
          >
            <div>
              <div
                className="align-center d-flex fw-bold-500"
                style={{ flexDirection: "column", rowGap: 7 }}
              >
                <span>
                  ¿Está seguro que desea cambiar el estado de la alerta?
                </span>
                <InputTextAreaComponent
                  errorMessage="Ingrese correctamente el comentario."
                  formName="description"
                  value={description}
                  onChange={(_formName: string, value: any) => {
                    setDescription(value);
                  }}
                  placeholder="Comentario"
                  rows={3}
                />
              </div>
              <div
                className="d-inline-flex "
                style={{
                  justifyContent: "flex-end",
                  marginTop: 12,
                  width: "100%",
                  columnGap: 12,
                }}
              >
                <button
                  className="clean-button"
                  type="button"
                  onClick={() => closeModal(true)}
                >
                  Cancelar
                </button>
                <button
                  className="custom-button"
                  form="modal-form"
                  type="submit"
                >
                  Cambiar
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </CardComponent>
  );
};

export default AlertCardComponent;
