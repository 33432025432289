export const GET_COUNTRYSIDES_MAP_DATA = "GET_COUNTRYSIDES_MAP_DATA";
export const GET_COUNTRYSIDES_MAP_DATA_FAIL = "GET_COUNTRYSIDES_MAP_DATA_FAIL";
export const GET_COUNTRYSIDES_MAP_DATA_SUCCESS =
  "GET_COUNTRYSIDES_MAP_DATA_SUCCESS";

export const GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID =
  "GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID";
export const GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID_FAIL =
  "GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID_FAIL";
export const GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID_SUCCESS =
  "GET_COUNTRYSIDE_FROM_SILO_BY_LOTE_ID_SUCCESS";

export const GET_LANZAS_BY_LOTE_ID = "GET_LANZAS_BY_LOTE_ID";
export const GET_LANZAS_BY_LOTE_ID_FAIL = "GET_LANZAS_BY_LOTE_ID_FAIL";
export const GET_LANZAS_BY_LOTE_ID_SUCCESS = "GET_LANZAS_BY_LOTE_ID_SUCCESS";

export const CLEAR_DATA = "CLEAR_DATA";
