import { Status } from "@googlemaps/react-wrapper";
import { ReactElement } from "react";
import { Spinner } from "react-bootstrap";

const SpinnerComponent = (status: Status) => {
  if (status === Status.LOADING) {
    return (
      <Spinner className="centered-spinner" animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <></>;
};

export default SpinnerComponent;
