import * as types from "./actionTypes";

const initialState = {
  metrics: [],
  metricsConfig: [],
  metricsDefaultConfig: [],
  metricsTypes: [],
  metricsSpeciesTypes: [],
  metricsMetricZones: [],
  metricActionsTypes: [],
  measurements: [],
  display: false,
  loading: false,
  error: {
    message: "",
  },
};

const AlertsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case types.GET_ALERTS_DATA:
    case types.GET_ALERTS_CONFIG_DATA:
    case types.GET_ALERT_ACTIONS_TYPE:
    case types.GET_ALERTS_SPECIES_DATA:
    case types.GET_ALERTS_TYPES_DATA:
    case types.GET_ALERTS_ZONES_DATA:
    case types.GET_MEASUREMENTS_BY_ID_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;

    case types.GET_ALERTS_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        metrics: actionPayload,
      };
      break;

    case types.SET_ALERTS_DISPLAY:
      state = {
        ...state,
        display: actionPayload,
      };
      break;

    case types.GET_ALERTS_CONFIG_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        metricsConfig: actionPayload.metricsConfig,
        metricsDefaultConfig: actionPayload.metricsDefaultConfig,
      };
      break;

    case types.GET_ALERTS_TYPES_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        metricsTypes: actionPayload,
      };
      break;

    case types.GET_ALERTS_ZONES_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        metricsMetricZones: actionPayload,
      };
      break;

    case types.GET_ALERTS_SPECIES_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        metricsSpeciesTypes: actionPayload,
      };
      break;

    case types.GET_ALERT_ACTIONS_TYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        metricActionsTypes: actionPayload,
      };
      break;

    case types.GET_MEASUREMENTS_BY_ID_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        measurements: actionPayload,
      };
      break;

    case types.GET_ALERT_ACTIONS_TYPE_FAIL:
    case types.GET_ALERTS_CONFIG_DATA_FAIL:
    case types.GET_ALERTS_DATA_FAIL:
    case types.GET_ALERTS_SPECIES_DATA_FAIL:
    case types.GET_ALERTS_TYPES_DATA_FAIL:
    case types.GET_ALERTS_ZONES_DATA_FAIL:
    case types.GET_MEASUREMENTS_BY_ID_DATA_FAIL:
      state = {
        ...state,
        error: {
          message: actionPayload,
        },
        loading: false,
      };
      break;
  }

  return state;
};

export default AlertsReducer;
