import React from "react";
import { NavBarComponent } from "../../components/base/navbar";
import { ToastComponent } from "../../components/toast";

const MainLayaout = (props: any) => {
  const {
    path,
    children,
    showToast,
    errorType,
    errorMessage,
    width,
    withPadding = true,
    setShowToast,
    setErrorType,
    setErrorMessage,
  } = props;

  const toggleShow = async () => {
    setErrorMessage(undefined);
    setShowToast(false);
    setErrorType(false);
  };

  return (
    <>
      {showToast && (
        <ToastComponent
          showToast={showToast}
          errorType={errorType}
          toggleShow={toggleShow}
          title={"Atencion!"}
          bodyContent={errorMessage}
        />
      )}
      <NavBarComponent mobile={width <= 768} path={path} />
      <div
        style={{
          padding: withPadding
            ? width <= 768
              ? "0.75rem 0.75rem"
              : "2.5rem 2.5rem"
            : "",
          flexGrow: 1,
        }}
      >
        {children}
      </div>
    </>
  );
};

export default MainLayaout;
