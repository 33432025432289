import { Alert } from "../../shared/types";
import * as types from "./actionTypes";

export const getUserToNotifyAction = (payload: any) => {
  return {
    type: types.GET_USER_TO_NOTIFY_ACTION,
    payload,
  };
};

export const getUserToNotifyFail = (error: any) => {
  return {
    type: types.GET_USER_TO_NOTIFY_FAIL,
    payload: error,
  };
};

export const getUserToNotifySuccess = (notificationData: Alert[]) => {
  return {
    type: types.GET_USER_TO_NOTIFY_SUCCESS,
    payload: notificationData,
  };
};

export const clearNotificationDataAction = () => {
  return {
    type: types.CLEAR_NOTIFICATION_DATA_ACTION,
  };
};

export const clearNotificationDataFail = (error: any) => {
  return {
    type: types.CLEAR_NOTIFICATION_DATA_FAIL,
    payload: error,
  };
};

export const clearNotificationDataSuccess = () => {
  return {
    type: types.CLEAR_NOTIFICATION_DATA_SUCCESS,
  };
};
