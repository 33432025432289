export const GET_SILOBAG = "GET_SILOBAG";
export const GET_SILOBAG_FAIL = "GET_SILOBAG_FAIL";
export const GET_SILOBAG_SUCCESS = "GET_SILOBAG_SUCCESS";

export const GET_COUNTRYSIDES = "GET_COUNTRYSIDES";
export const GET_COUNTRYSIDES_FAIL = "GET_COUNTRYSIDES_FAIL";
export const GET_COUNTRYSIDES_SUCCESS = "GET_COUNTRYSIDES_SUCCESS";

export const GET_LOT = "GET_LOT";
export const GET_LOT_FAIL = "GET_LOT_FAIL";
export const GET_LOT_SUCCESS = "GET_LOT_SUCCESS";

export const GET_SPECIES = "GET_SPECIES";
export const GET_SPECIES_FAIL = "GET_SPECIES_FAIL";
export const GET_SPECIES_SUCCESS = "GET_SPECIES_SUCCESS";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";

export const GET_HARVEST = "GET_HARVEST";
export const GET_HARVEST_FAIL = "GET_HARVEST_FAIL";
export const GET_HARVEST_SUCCESS = "GET_HARVEST_SUCCESS";

export const GET_ALL_AVAILABLE_DEVICE_ACTION =
  "GET_ALL_AVAILABLE_DEVICE_ACTION";
export const GET_ALL_AVAILABLE_DEVICE_FAIL = "GET_ALL_AVAILABLE_DEVICE_FAIL";
export const GET_ALL_AVAILABLE_DEVICE_SUCCESS =
  "GET_ALL_AVAILABLE_DEVICE_SUCCESS";

export const UPDATE_SILOBAGS_FILTERED_LIST = "UPDATE_SILOBAGS_FILTERED_LIST";
export const UPDATE_SILOBAGS_FILTERED_LIST_SUCCESS =
  "UPDATE_SILOBAGS_FILTERED_LIST_SUCCESS";
export const UPDATE_SILOBAGS_FILTERED_LIST_FAIL =
  "UPDATE_SILOBAGS_FILTERED_LIST_FAIL";

export const GET_DEVICE_TO_ATTACH_ACTION = "GET_DEVICE_TO_ATTACH_ACTION";
export const GET_DEVICE_TO_ATTACH_SUCCESS = "GET_DEVICE_TO_ATTACH_SUCCESS";
export const GET_DEVICE_TO_ATTACH_FAIL = "GET_DEVICE_TO_ATTACH_FAIL";

export const GET_DEVICE_ACTION_TYPE_ACTION = "GET_DEVICE_ACTION_TYPE_ACTION";
export const GET_DEVICE_ACTION_TYPE_SUCCESS = "GET_DEVICE_ACTION_TYPE_SUCCESS";
export const GET_DEVICE_ACTION_TYPE_FAIL = "GET_DEVICE_ACTION_TYPE_FAIL";
