import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import backgrounIOFCompany from "../../assets/img/LoginPage/backgrounIOFCompany.webp";
import IOFLogo from "../../assets/img/LoginPage/logo-white.svg";
import { useNavigate } from "react-router-dom";
import { ReduxType } from "../../shared/types";
import { ToastComponent } from "../../components/toast";
import { Paths } from "../../config/paths";
import { FooterComponent } from "../../components/base";

const LoginLayaout = (props: any) => {
  const [localShow, setLocalShow] = useState(false);
  const [localErrorType, setLocalErrorType] = useState<boolean>(false);
  const [localErrorMessage, setLocalErrorMessage] = useState<any>();

  const {
    children,
    errorType,
    errorMessage,
    width,
    showToast,
    setShowToast,
    setErrorType,
    setErrorMessage,
  } = props;

  const navigate = useNavigate();

  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (userData.error && userData.error.message) {
      setAndShowError(userData.error.message, true);
    }
  }, [userData.error]);

  useEffect(() => {
    if (userData.userReset) {
      setAndShowError(userData.userReset, false);
      navigate(Paths.LOGIN);
    }
  }, [userData.userReset]);

  const setAndShowError = (error: any, errorType: boolean) => {
    setLocalErrorType(errorType);
    setLocalErrorMessage(error);
    toggleShow();
  };

  const localToggleShow = () => setLocalShow(!localShow);

  const toggleShow = async () => {
    setErrorMessage(undefined);
    setShowToast(false);
    setErrorType(false);
  };
  return (
    <>
      <Row
        className="justify-content-center login-layout-container"
        align="middle"
      >
        <Col
          md={5}
          style={{
            height: width <= 768 ? "50vh" : "100vh",
            backgroundSize: "cover",
            backgroundImage: `url(${backgrounIOFCompany})`,
          }}
        >
          <Image
            style={{
              height: "143.88",
              width: width <= 768 ? "10rem" : "18rem",
              marginTop: width <= 768 ? "20vh" : "40vh",
            }}
            src={IOFLogo}
          />
        </Col>
        <Col md={7} className="login-form-container">
          {showToast && (
            <ToastComponent
              showToast={showToast}
              errorType={errorType}
              toggleShow={toggleShow}
              title={"Atencion!"}
              bodyContent={errorMessage}
            />
          )}

          <ToastComponent
            showToast={localShow}
            errorType={localErrorType}
            toggleShow={localToggleShow}
            title={"Atencion!"}
            bodyContent={localErrorMessage}
          />

          {children}
          <FooterComponent width={width} />
        </Col>
      </Row>
    </>
  );
};

export default LoginLayaout;
