export const Endpoints = {
  API_IOF: {
    AUTH: "login",
    ALERTS: "alerts",
    ALERT_ACTIONS: "alert_actions",
    ALERT_ACTIONS_TYPES: "alertActionsTypes",
    CITY: "municipalities",
    CMCS: "cmcs",
    COMPANIES: "companies",
    COUNTRYSIDES: "countrysides",
    DASHBOARD_INFO: "dashboardInformation",
    DEVICES: "devices",
    DEVICES_UNLINK: "devices/unorganized",
    FIRST_VIEW: "rpc/first_map_view",
    FREE_DEVICES: "available_devices",
    HARVESTS: "harvests",
    SECOND_VIEW: "rpc/second_map_view",
    SECOND_VIEW_DEVICE_NAV: "rpc/second_map_view_devices_navbar",
    SECOND_VIEW_POINT_TOOLTIPS: "rpc/second_map_view_points_tooltips",
    LOCALITIES: "localities",
    MEASUREMENTS: "measurements",
    METRIC_ALERT_CONFIG: "metricsAlertConfig",
    METRIC_ALERT_TYPE: "alertActionsTypes",
    METRIC_ALERT_ZONES: "metricAlertZones",
    METRIC_ALERT_SPECIES: "silobagsSpeciesTypes",
    NOTIFICATION: "alert_notifications",
    NOTIFICATION_RECIPIENTS: "alert_notification_recipients",
    ORGANIZATIONS: "organizations",
    PERMISSIONS: "permissions",
    USER_PERMISSIONS: "users_permissions",
    USER_DENIED_PERMISSIONS: "user_denied_permissions",
    ROLES: "roles",
    SILOBAGS: "silobags",
    SILO_PRODUCT: "silobagsProductTypes",
    SILO_SPECIES: "silobagsSpeciesTypes",
    USERS: "users",
    LOTS: "lots",
  },
  AUTH: {
    LOGIN: "token?grant_type=password",
    LOGIN_REFRESH: "token?grant_type=refresh_token",
    SIGNUP: "signup",
    VERIFY: "verify",
    RECOVER: "recover",
    USERS: {
      CREATE: "admin/users",
      UPDATE: "admin/users",
      DELETE: "admin/users",
      PUT: "user",
    },
  },
  WOZALABS: {
    DEVICES: {
      GET_FREE_DEVICES: "v_free_devices?organization_id=eq.",
      GET_DEVICE_TO_ATTACH_FILE: "devices?name=eq.deviceToAttachFiles",
      GET_DEVICE_ACTION_TYPE: "silobags_devices_action_types?name=eq.cmcs",
    },
    SILO_DEVICE: {
      CREATE: "silobags_devices",
      UPDATE: "silobags_devices?id=eq.",
      DELETE: "silobags_devices?id=in.",
    },
    SILO_DEVICE_ACTION: {
      CREATE: "silobags_devices_actions",
    },
  },
};
