import {
  getCurrentProfileUser,
  getCurrentUser,
  getToken,
} from "../../core/auth/AuthService";
import * as types from "./actionTypes";

const initialState = {
  user: { username: getCurrentUser(), access_token: getToken() },
  userProfile: getCurrentProfileUser(),
  userReset: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const UserReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.CLEAN_USER:
    case types.LOGIN_USER:
    case types.VERIFY_USER_ACTION:
      state = { ...state, loading: true };
      break;

    case types.LOGIN_USER_SUCCESS:
      const userNameAndTokens = {
        username: actionPayload.username,
        access_token: actionPayload.access_token,
        refresh_token: actionPayload.refresh_token,
      };
      state = {
        ...state,
        user: userNameAndTokens,
        userProfile: actionPayload.profileData,
        loading: false,
      };
      break;

    case types.CLEAN_USER_SUCCESS:
      state = {
        ...state,
        userReset: undefined,
        error: {
          message: "",
        },
        loading: false,
      };
      break;

    case types.VERIFY_USER_SUCCESS:
      state = {
        ...state,
        user: actionPayload,
        userProfile: actionPayload,
        loading: false,
      };
      break;

    case types.CLEAN_USER_FAIL:
    case types.LOGIN_USER_FAIL:
    case types.VERIFY_USER_FAIL:
      state = {
        ...state,
        loading: false,
        error: { message: actionPayload },
      };
      break;
  }

  return state;
};

export default UserReducer;
