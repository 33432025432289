import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL_IOF, API_WOZALABS_URL } from "../../config/general-config";
import { apiGet } from "../../shared/ApiService";
import {
  getAlertActionTypesAction,
  getAlertActionTypesFail,
  getAlertActionTypesSuccess,
  getAlertsConfigDataFail,
  getAlertsConfigDataSuccess,
  getAlertsDataFail,
  getAlertsDataSuccess,
  getAlertsSpeciesDataFail,
  getAlertsSpeciesDataSuccess,
  getAlertsTypesDataFail,
  getAlertsTypesDataSuccess,
  getAlertsZonesDataFail,
  getAlertsZonesDataSuccess,
  getMeasurementsByIdFail,
  getMeasurementsByIdSuccess,
} from "./actions";
import {
  GET_ALERTS_CONFIG_DATA,
  GET_ALERTS_DATA,
  GET_ALERTS_SPECIES_DATA,
  GET_ALERTS_TYPES_DATA,
  GET_ALERTS_ZONES_DATA,
  GET_ALERT_ACTIONS_TYPE,
  GET_MEASUREMENTS_BY_ID_DATA,
} from "./actionTypes";

function* onGetAlertActionsTypeData() {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.ALERT_ACTIONS_TYPES}`,
        })
      );
    });
    yield put(getAlertActionTypesSuccess(response));
  } catch (error: any) {
    yield put(getAlertActionTypesFail(error.response));
  }
}

function* onGetAlertsData(action: any) {
  try {
    const { org_id } = action.payload;

    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.ALERTS}`,
        })
      );
    });

    yield put(getAlertsDataSuccess(response));
  } catch (error: any) {
    yield put(getAlertsDataFail(error.response));
  }
}

function* onGetAlertsConfigData(action: any) {
  try {
    const { org_id } = action.payload;

    const responseDefaultMetricConfig: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.METRIC_ALERT_CONFIG}`,
        })
      );
    });

    const responseMetricConfig: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.METRIC_ALERT_CONFIG}`,
        })
      );
    });

    const response = {
      metricsDefaultConfig: responseDefaultMetricConfig,
      metricsConfig: responseMetricConfig,
    };

    yield put(getAlertsConfigDataSuccess(response));
  } catch (error: any) {
    yield put(getAlertsConfigDataFail(error.response));
  }
}

function* onGetAlertsTypesData() {
  try {
    let response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.METRIC_ALERT_TYPE}`,
        })
      );
    });

    response = response.filter(
      (e) => !e.name.includes("ZONA") && !e.name.includes("MOVIMIENTO")
    );

    yield put(getAlertsTypesDataSuccess(response));
  } catch (error: any) {
    yield put(getAlertsTypesDataFail(error.response));
  }
}

function* onGetAlertsZonesData() {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.METRIC_ALERT_ZONES}`,
        })
      );
    });

    yield put(getAlertsZonesDataSuccess(response));
  } catch (error: any) {
    yield put(getAlertsZonesDataFail(error.response));
  }
}

function* onGetAlertsSpeciesData() {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.METRIC_ALERT_SPECIES}`,
        })
      );
    });

    yield put(getAlertsSpeciesDataSuccess(response));
  } catch (error: any) {
    yield put(getAlertsSpeciesDataFail(error.response));
  }
}

function* onGetMeasurementsByIdData(action: any) {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL_IOF}/${Endpoints.API_IOF.MEASUREMENTS}/${action.measurementIds}`,
        })
      );
    });

    yield put(getMeasurementsByIdSuccess(response));
  } catch (error: any) {
    yield put(getMeasurementsByIdFail(error.response));
  }
}

function* AlertsSaga() {
  yield takeLatest(GET_ALERT_ACTIONS_TYPE, onGetAlertActionsTypeData);
  yield takeLatest(GET_ALERTS_DATA, onGetAlertsData);
  yield takeLatest(GET_ALERTS_CONFIG_DATA, onGetAlertsConfigData);
  yield takeLatest(GET_ALERTS_TYPES_DATA, onGetAlertsTypesData);
  yield takeLatest(GET_ALERTS_SPECIES_DATA, onGetAlertsSpeciesData);
  yield takeLatest(GET_ALERTS_ZONES_DATA, onGetAlertsZonesData);
  yield takeLatest(GET_MEASUREMENTS_BY_ID_DATA, onGetMeasurementsByIdData);
}

export default AlertsSaga;
