import * as _ from "lodash";
import moment from "moment";
import { getPointerIcon } from "../components/map/mapDashboard/helper";
import {
  Alert,
  MetricsConfig,
  ReduxMetricsMapData,
  ReduxUserData,
  roles,
} from "./types";

import Telegram from "../assets/icons/svg/AdministratorPage/telegram.svg";
import Sms from "../assets/icons/svg/AdministratorPage/sms.svg";
import Whatsapp from "../assets/icons/svg/AdministratorPage/whatsapp.svg";
import Mail from "../assets/icons/svg/AdministratorPage/mail.svg";

import Confirm from "../assets/icons/svg/Alerts/confirm.svg";
import Danger from "../assets/icons/svg/Alerts/danger.svg";
import NoBell from "../assets/icons/svg/Alerts/no-bell.svg";
import Reset from "../assets/icons/svg/Alerts/reset.svg";

import MOVIcon from "../assets/icons/svg/SiloBagPage/view/movIcon.svg";

import ArrozPuitaIcon from "../assets/icons/svg/DashboardPage/ico-especies/arroz-puita.svg";
import ArrozIcon from "../assets/icons/svg/DashboardPage/ico-especies/arroz.svg";
import MaizIcon from "../assets/icons/svg/DashboardPage/ico-especies/maiz.svg";
import SojaIcon from "../assets/icons/svg/DashboardPage/ico-especies/soja.svg";
import TrigoIcon from "../assets/icons/svg/DashboardPage/ico-especies/trigo.svg";
import EstablishmentIcon from "../assets/icons/svg/DashboardPage/ico-estab.svg";
import { Paths } from "../config/paths";
import { Badge } from "react-bootstrap";
import { isEmpty } from "lodash";
import ReactDOMServer from "react-dom/server";
import compareDesc from "date-fns/compareDesc";
import { SiloBagPdfComponent } from "../components/silobag/siloBagPdfTemplate";
import { pdf } from "@react-pdf/renderer";

const establishmentIcon = ReactDOMServer.renderToString(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="18 0 100 100"
    fill="none"
  >
    <g filter="url(#a)">
      <path
        d="M69.22 10C52.006 10 38 23.953 38 41.082c-.113 25.057 30.033 45.833 31.22 46.673 0 0 31.332-21.616 31.219-46.653C100.439 23.953 86.433 10 69.219 10Z"
        fill="#1481BC"
      />
    </g>
    <path
      d="M69.507 62.193c11.387 0 20.617-9.23 20.617-20.617s-9.23-20.617-20.617-20.617c-11.386 0-20.616 9.23-20.616 20.617 0 11.386 9.23 20.617 20.616 20.617Z"
      fill="#fff"
    />
    <path
      d="M59.8 41.228V40.9c.11-.24.31-.375.543-.492 1.411-.691 2.818-1.395 4.229-2.087.134-.066.184-.139.182-.291-.008-.875-.004-1.745-.004-2.617 0-.063-.007-.126-.012-.207-.123.07-.218.131-.324.182a.584.584 0 0 1-.653-.06.579.579 0 0 1-.228-.579c.035-.226.183-.367.375-.481 1.987-1.189 3.972-2.38 5.956-3.575.283-.17.539-.174.82 0 .69.424 1.387.836 2.082 1.253l3.924 2.355c.327.199.425.56.245.86-.18.302-.546.383-.874.193l-.265-.154v.234c0 .832.01 1.663-.006 2.494-.005.23.066.341.273.442 1.407.684 2.803 1.394 4.212 2.076.48.232.534.63.408.89-.168.34-.53.425-.948.218-.064-.032-.13-.06-.206-.095v8.808h.578c.319.006.554.19.624.486.064.27-.077.546-.354.689-.029.015-.055.034-.081.052H60.25a.944.944 0 0 1-.45-.45v-.328c.24-.573.759-.432 1.21-.453v-8.802c-.069.031-.118.052-.166.075-.508.249-.758.175-1.044-.308Zm18.483 9.032c.004-.048.009-.075.009-.101 0-3.06 0-6.12.003-9.182 0-.118-.047-.164-.142-.212-1.05-.517-2.097-1.043-3.149-1.556-.307-.15-.44-.373-.437-.71.008-1.29 0-2.578.006-3.866a.258.258 0 0 0-.14-.26 929.94 929.94 0 0 1-4.014-2.409c-.11-.067-.187-.059-.294.005-1.33.801-2.662 1.6-3.997 2.394a.273.273 0 0 0-.154.273c.006 1.282-.002 2.564.007 3.845.003.358-.14.585-.46.74-1.03.5-2.05 1.015-3.079 1.518-.138.067-.193.142-.192.305.007 2.999.008 5.998.005 8.998v.215h2.495V42.4c0-.49.236-.722.73-.722h9.573c.516 0 .743.228.743.75v7.831h2.487Zm-8.94-3.675-3.355-2.933v5.868l3.354-2.935Zm5.216 2.934v-5.867l-3.352 2.934 3.352 2.933Zm-4.285-3.749 3.254-2.848H67.02l3.254 2.848Zm3.265 4.488-3.265-2.856-3.261 2.856h6.526Z"
      fill="#1481BC"
    />
    <path
      d="M70.257 39.223H69.11c-.425 0-.681-.25-.678-.67.005-.503-.026-1.013.044-1.508.13-.916.989-1.557 1.924-1.498.92.058 1.678.829 1.708 1.751.014.438.01.874 0 1.309-.006.358-.266.61-.629.615-.404.005-.813 0-1.222.001Zm-.595-1.24h1.224c0-.224.01-.438 0-.644a.612.612 0 0 0-1.22 0c-.015.207-.004.418-.004.643Z"
      fill="#1481BC"
    />
    <defs>
      <filter
        id="a"
        x=".301"
        y=".575"
        width="137.838"
        height="153.153"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation=".589" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2_10" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.712" />
        <feGaussianBlur stdDeviation="4.712" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend
          in2="effect1_dropShadow_2_10"
          result="effect2_dropShadow_2_10"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="18.85" />
        <feGaussianBlur stdDeviation="14.137" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend
          in2="effect2_dropShadow_2_10"
          result="effect3_dropShadow_2_10"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="28.274" />
        <feGaussianBlur stdDeviation="18.85" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend
          in2="effect3_dropShadow_2_10"
          result="effect4_dropShadow_2_10"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect4_dropShadow_2_10"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const asyncLocalStorage = {
  setItem: function (key: string, value: string) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  removeItem: function (key: string) {
    return Promise.resolve().then(function () {
      localStorage.removeItem(key);
    });
  },
};

const sortBy = (key: any) => {
  return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export const formatTons = (value: number): any => {
  return value / 1000;
};

export const checkToleranceNumbers = (
  numsArray: number[],
  toleranceNumber: number
) => {
  let min = Math.min(...numsArray);
  return numsArray.every((x) => Math.abs(min - x) <= toleranceNumber);
};

export const getUserProfile = (userData: ReduxUserData) => {
  return userData &&
    userData.userProfile &&
    typeof userData.userProfile === "string"
    ? JSON.parse(userData.userProfile)
    : userData.userProfile;
};

export const getUserName = (userData: any) => {
  if (userData && userData.userProfile) {
    if (typeof userData.userProfile === "string") {
      const user = JSON.parse(userData.userProfile);
      if (user && user.username) return user.username;
    } else {
      if (userData.userProfile.username) return userData.userProfile.username;
    }
  }
  return "";
};

export const getProfileEmail = (userData: any) => {
  if (userData && userData.userProfile) {
    if (typeof userData.userProfile === "string") {
      const user = JSON.parse(userData.userProfile);
      if (user && user.email) return user.email;
    } else {
      if (userData.userProfile.email) return userData.userProfile.email;
    }
  }
  return "";
};

export const isCrudEnabled = (userProfile: any, path: string) => {
  if (
    ![
      Paths.SILO,
      Paths.NEW_SILO,
      Paths.VIEW_SILO,
      Paths.NOTIFICATION,
      Paths.ADMINISTRATOR,
    ].includes(path as Paths)
  )
    return true;
  const permission =
    userProfile &&
    userProfile.user_permissions &&
    userProfile.user_permissions.find((value: any) => {
      if (!value.active) return false;
      if (
        value &&
        ((value.permissions && value.permissions.name === "Silos") ||
          value.permission_id == 10) &&
        [Paths.SILO, Paths.NEW_SILO, Paths.VIEW_SILO].includes(path as Paths)
      )
        return true;

      if (
        [
          2, 3, 6, 8,
          /*
            "Compañías",
            "Establecimientos",
            "Métricas alertas",
            "Usuarios",
          */
        ].includes(value.permission_id) && //value.permissions.name
        path === Paths.ADMINISTRATOR
      )
        return true;

      if (path === Paths.NOTIFICATION && value.active) return true;
      return value.active;
    });
  return permission ? true : false;
};

export const scrollNavOverflowHandler = (
  element: React.UIEvent<HTMLElement>
) => {
  const el = element.currentTarget;
  const isLeft = el.scrollWidth > el.clientWidth;

  if (!isLeft) {
    el.classList.remove("is-left-overflowing", "is-right-overflowing");
    return;
  }
  const fullScrollRight = el.scrollWidth - el.clientWidth + el.scrollLeft;
  const isScrolledToRight = checkToleranceNumbers(
    [el.scrollLeft * 2, fullScrollRight],
    2
  );
  const isScroledlToLeft = el.scrollLeft === 0;

  el.classList.toggle("is-left-overflowing", !isScrolledToRight);
  el.classList.toggle("is-right-overflowing", !isScroledlToLeft);
};

const findIndex = (arrayElements: any, key: string, match: string | number) => {
  return _.findIndex(arrayElements, (i: any) => {
    if (key.includes(".")) {
      const dkey: any = key.split(".");
      if (dkey.length == 2) {
        return i[dkey[0]][dkey[1]] == match;
      }
    } else {
      return i[key] == match;
    }
  });
};

const formatPermission = (rol: roles) => {
  switch (rol) {
    case roles.ADMIN:
      return "Administrador";
    case roles.EDITOR:
      return "Editor";
    case roles.OWNER:
      return "Dueño";
    case roles.READ_ONLY:
      return "Solo Lectura";
    case roles.REPORT_RECEIBABLES:
      return "Reportar cuentas por cobrar";
    case roles.ORGANIZATION_ADMIN:
      return "Administrador de la Organizacion";
    case roles.ORGANIZATION_COMMERCIAL:
      return rol;
    case roles.ORGANIZATION_COUNTRYSIDE:
      return rol;
    case roles.ORGANIZATION_GENERAL:
      return rol;
    case roles.ORGANIZATION_SUBZONE:
      return rol;
    case roles.ORGANIZATION_TECHNICAL:
      return rol;
    case roles.ORGANIZATION_ZONE:
      return rol;
    default:
      return rol;
  }
};

const alertColor = (
  alertColor: "orange" | "red" | "green" | "yellow" | string | null
) => {
  switch (alertColor) {
    case "yellow":
      return "#F4C525";
    case "orange":
      return "#FD7E14";
    case "red":
      return "#FF0000";
    case "green":
      return "#6BAA00";
    default:
      return "#6BAA00";
  }
};

const getTextColor = (
  color: "orange" | "red" | "green" | "yellow" | string | null
) => "white";

const formatDate = (date: any) => {
  if (!date || date === "") return;
  return moment(date).format("DD/MM/YYYY");
};

const diffDates = (fechaArmado: any) => {
  let diffDays = 0;
  if (fechaArmado) {
    const date1 = moment(fechaArmado);
    const date2 = moment(new Date());
    diffDays = date2.diff(date1, "days");
  }
  return `${diffDays} días`;
};

const formatDatetime = (date: any) => {
  return moment(date).format("DD/MM/YYYY");
};

const formatDatetimeToSave = (date: any) => {
  return moment(date).format("YYYY/MM/DD HH:mm:ss");
};

const cloneWithoutReference = (elements: any) => {
  return JSON.parse(JSON.stringify(elements));
};

const stateSiloBagName: any = {
  1: "Datos incompletos",
  2: "Datos completos",
  3: "Certificable",
};

const stateSiloBagColorText: any = {
  1: "light-orange",
  2: "light-yellow",
  3: "light-green",
};

const stateSiloBagColorBackground: any = {
  1: "background-orange",
  2: "background-yellow",
  3: "background-green",
};

const findCity = (formValues: any, orgData: any, name: string) => {
  if (!formValues.country_l2_id) {
    formValues.locality_id = null;
    formValues.municipality_id = null;
    return formValues;
  }
  if (
    orgData.cities &&
    orgData.cities.length > 0 &&
    orgData.localities &&
    orgData.localities.length > 0
  ) {
    const firstIndex =
      name === "countrysides"
        ? findIndex(
            orgData.localities,
            "country_l2_id",
            formValues.country_l2_id.id
          )
        : findIndex(orgData.localities, "id", formValues.locality_id);
    if (firstIndex !== -1) {
      const secondIndex = findIndex(
        orgData.cities,
        "id",
        orgData.localities[firstIndex].municipality_id
      );
      if (secondIndex !== -1) {
        if (name === "countrysides") {
          formValues.locality_id = orgData.localities[firstIndex].id;
        }
        formValues.municipality_id = orgData.cities[secondIndex].id;
      }
    }
  }
  return formValues;
};

const convertSvgToDataImage = (
  elemento_producto: any,
  alerta_color: string
) => {
  const icon = getPointerIcon(elemento_producto);
  const svg = icon.replace(
    "{{ color }}",
    alertColor(alerta_color) || "#6BAA00"
  );
  return "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svg);
};

const getMarkdownContent = async (readmePath: string) => {
  const response = await fetch(readmePath);
  return await response.text();
};

const notificationQualityData: any = {
  quality_intermediate: { name: "Calidad intermedia", color: "yellow" },
  quality: { name: "Calidad grave", color: "orange" },
  security: { name: "Seguridad", color: "red" },
};

const notificationMediaData: any = {
  email: { name: "Email", icon: Mail },
  whatsapp: { name: "Whatsapp", icon: Whatsapp },
  sms: { name: "SMS", icon: Sms },
  telegram: { name: "Telegram", icon: Telegram },
};

const getNotificationData = (
  name: string,
  type: "QUALITY" | "NOTIFICATION"
) => {
  switch (type) {
    case "QUALITY":
      return notificationQualityData[name];
    case "NOTIFICATION":
      return notificationMediaData[name];
  }
};

const jsonResponsForQuality: any = {
  security: false,
  quality: false,
  quality_intermediate: false,
};

const jsonResponseForNotification: any = {
  email: false,
  whatsapp: false,
  sms: false,
  telegram: false,
};

const getValueFromQueue = (
  data: any[] | undefined,
  index: number,
  names: string[],
  jsonType: "QUALITY" | "NOTIFICATION"
) => {
  const jsonResponse =
    jsonType == "QUALITY" ? jsonResponsForQuality : jsonResponseForNotification;
  const alertArray =
    jsonType == "QUALITY"
      ? "alertQualityNotificationData"
      : "alertNotificationMediaData";
  const arrayNames =
    jsonType == "QUALITY" ? notificationQualityData : notificationMediaData;
  if (index !== -1 && data && data[index]) {
    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const nameToFind = arrayNames[names[i]] && arrayNames[names[i]].name;
      const newIndex = findIndex(
        data[index][alertArray],
        "name",
        `${nameToFind}`
      );
      if (newIndex !== -1) {
        jsonResponse[name] = data[index][alertArray][newIndex].active;
      }
    }
  }
  return jsonResponse;
};

const titleCase = (str: string) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

const getIconFromProduct = (product: string) => {
  switch (true) {
    case [
      "Maiz",
      "Maíz",
      "Maíz waxy",
      "Maíz dentado",
      "Maíz pisingallo",
      "Maíz blanco",
      "Girasol",
    ].includes(product):
      return MaizIcon;
    case product.includes("Soja"):
      return SojaIcon;
    case product.includes("Arroz") && !product.includes("Puita"):
      return ArrozIcon;
    case product.includes("Trigo") || product.includes("Sorgo"):
      return TrigoIcon;
    case product.includes("Puita"):
      return ArrozPuitaIcon;
  }
  return EstablishmentIcon;
};

const optionTypes: any = {
  Pendiente: { dropdown: false, img: Danger },
  "En revisión": { dropdown: false, img: Reset },
  Resuelta: { dropdown: false, img: Confirm },
  "Recordarme en": { dropdown: true },
  Eliminar: { dropdown: false, img: NoBell },
};

const getAlertActionInfo = (alertName: string) => {
  return optionTypes[alertName];
};

const alertKey: any = {
  CO2: "co2_percent",
  HUM: "humidity_percent",
  TEMP: "temperature_celsius",
};

const getRenderDataForDevices = (
  metricsData: ReduxMetricsMapData,
  record: any,
  silobagId: number,
  short_name: string,
  symbol = "%"
) => {
  const isMov = short_name === "MOV";
  const alerts = [...metricsData.metrics].filter(
    (da: Alert) =>
      da.device_id == record.devices.id &&
      da.silobag_id == silobagId &&
      da.metrics.short_name == short_name
  );
  const maxUpdated = [...alerts]
    .filter((val) => val.updated_at)
    .reduce(
      (a, b) => {
        return new Date(a.updated_at) > new Date(b.updated_at) ? a : b;
      },
      { updated_at: 0 } as any
    );
  const maxCreated = [...alerts].reduce(
    (a, b) => {
      return new Date(a.created_at!) > new Date(b.created_at!) ? a : b;
    },
    { created_at: 0 } as any
  );
  const finalAlert =
    maxUpdated.updatedAt > maxCreated.created_at ? maxUpdated : maxCreated;

  const color = finalAlert && finalAlert.colors && finalAlert.colors.hex;
  const alertValue = finalAlert && finalAlert.measurement_value;
  const percentage = isMov ? (
    alertValue ? (
      "MOV"
    ) : (
      <span style={{ marginLeft: "1.2vh", marginRight: "1.2vh" }}>
        <img style={{ width: 16, height: 12 }} src={MOVIcon} />
      </span>
    )
  ) : finalAlert && finalAlert.measurement_value ? (
    `${parseFloat(finalAlert.measurement_value).toFixed(2)} ${symbol}`
  ) : (
    `-${symbol}`
  );

  const letterColor =
    isMov || percentage == `-${symbol}` ? "#000000" : "#FFFFFF";

  const alertStyle = {
    backgroundColor: color,
    color: letterColor,
    paddingTop: "1.5vh",
    paddingBottom: "1.5vh",
    paddingLeft: "2.5vh",
    paddingRight: "2.5vh",
    border:
      (percentage != "MOV" && isMov) || percentage == `-${symbol}`
        ? "1px solid"
        : "none",
  };

  if (!alertValue && !isMov) {
    return getRenderMeasurementDataForDevices(
      metricsData,
      record,
      silobagId,
      alertKey[short_name],
      alertStyle
    );
  }

  return (
    <>
      <Badge
        pill
        bg="custom"
        className="fs-08"
        style={{
          ...alertStyle,
        }}
      >
        <span className="align-middle">{percentage}</span>
      </Badge>
    </>
  );
};

const getRenderMeasurementDataForDevices = (
  metricsData: ReduxMetricsMapData,
  record: any,
  silobagId: number,
  keyData: string,
  alertStyle: any | undefined = undefined
) => {
  let measurement: any;
  const alert = [...metricsData.metrics].filter(
    (da: Alert) =>
      da.device_id == record.devices.id && da.silobag_id == silobagId
  );
  const maxUpdated = [...alert]
    .filter((val) => val.updated_at)
    .reduce(
      (a, b) => {
        return new Date(a.updated_at) > new Date(b.updated_at) ? a : b;
      },
      { updated_at: 0 } as any
    );
  const maxCreated = [...alert].reduce(
    (a, b) => {
      return new Date(a.created_at!) > new Date(b.created_at!) ? a : b;
    },
    { created_at: 0 } as any
  );
  const finalAlert =
    maxUpdated.updatedAt > maxCreated.created_at ? maxUpdated : maxCreated;

  if (finalAlert && finalAlert.measurement_id) {
    measurement = metricsData.measurements.find(
      (m: any) => m.id == finalAlert.measurement_id
    );
  }

  const measurementStyle = {
    backgroundColor: "transparent",
    color: "#000000",
    height: "3.5vh",
    width: "8vh",
  };

  return (
    <>
      <Badge
        pill
        bg="custom"
        className="fs-08"
        style={alertStyle ? { ...alertStyle } : { ...measurementStyle }}
      >
        <span
          className={alertStyle ? "align-middle" : ""}
          style={
            !alertStyle
              ? {
                  display: "flex",
                  marginTop: "0.5vh",
                }
              : {}
          }
        >
          {`${
            (measurement &&
              measurement[keyData] &&
              parseFloat(measurement[keyData]).toFixed(2)) ||
            "-"
          } % `}
        </span>
      </Badge>
    </>
  );
};

const generateAlertData = (
  metric: MetricsConfig,
  metric_alert_type_id: number
) => {
  const isMOV = metric && metric.short_name === "MOV";
  const isTEMP = metric && metric.short_name === "TEMP";
  const maxPossibleValue = isMOV ? 5 : isTEMP ? 50 : 100;

  let alertVal = [
    metric && metric.min_possible_value ? metric.min_possible_value : 0,
  ];

  let metric_config = metric.metrics_alerts_config.filter(
    (ele: any) => ele.metric_alert_type_id === metric_alert_type_id
  );

  if (metric_config.length > 0) {
    if (isMOV) {
      alertVal = [metric_config[0].min_value];
    } else {
      metric_config.map((ele: any) => {
        alertVal.push(ele.min_value);
        alertVal.push(ele.max_value);
        // eslint-disable-next-line array-callback-return
        return;
      });
    }
  }

  if (!isMOV) {
    alertVal.push(
      metric && metric.max_possible_value
        ? metric.max_possible_value
        : maxPossibleValue
    );
  }

  return alertVal;
};

/**
 * Estandarizar el array que contiene los datos de las silo bolas
 * en formato key:value o key:{name:value} para generalizar el proceso
 * de sorting.
 * @param siloBags
 * @returns array de silo bolsas preparado para ordenar.
 */
const applyFiltersToSiloBags = (siloBags: any, selectedFilter: any) => {
  if (isEmpty(siloBags)) return [];

  return siloBags.filter((siloBag: any) => {
    const filters = [];

    if (selectedFilter.species) {
      filters.push(siloBag.silobag_species_types.id == selectedFilter.species);
    }

    if (selectedFilter.harvest) {
      filters.push(siloBag.silobag_harvests.id == selectedFilter.harvest);
    }

    if (selectedFilter.countrysides) {
      filters.push(
        siloBag.lots_country_sides.id == selectedFilter.countrysides
      );
    }

    if (selectedFilter.lots) {
      filters.push(siloBag.lots.id == selectedFilter.lots);
    }

    if (selectedFilter.status) {
      filters.push(siloBag.silobag_data_status_id == selectedFilter.status);
    }

    if (selectedFilter.from) {
      const from = new Date(selectedFilter.from);
      const createdAt = new Date(siloBag.created_at);
      filters.push(createdAt.getTime() >= from.getTime());
    }

    if (selectedFilter.to) {
      const to = new Date(selectedFilter.to);
      const createdAt = new Date(siloBag.created_at);
      filters.push(createdAt.getTime() <= to.getTime());
    }

    return !filters.some((row: any) => !row);
  });
};

const groupAlertsAndSort = (alerts: Alert[]): Alert[][] => {
  const alertsBySilos = alerts.reduce((group: any, alert) => {
    const { silobag_id } = alert;
    group[silobag_id] = group[silobag_id] ?? [];
    group[silobag_id].push(alert);
    return group;
  }, {});

  for (const siloAlerts in alertsBySilos) {
    alertsBySilos[siloAlerts].sort((a: Alert, b: Alert) => b.color_id);
  }

  const groupedAlerts = Object.values(alertsBySilos) as Alert[][];
  groupedAlerts.sort((a: Alert[], b: Alert[]) =>
    compareDesc(a[0].measurement_created_at, b[0].measurement_created_at)
  );

  return groupedAlerts;
};

const getExtraImgs = (data: any, showDeviceImg: boolean): any[] => {
  const extras: any[] = [];
  if (data && data.silobags_devices && data.silobags_devices.length > 0) {
    data.silobags_devices.sort(sortBy("id")).forEach((elem: any) => {
      if (
        elem.silobags_devices_actions &&
        elem.silobags_devices_actions.length > 0
      )
        elem.silobags_devices_actions.forEach((e: any) => {
          if (!showDeviceImg && e.silobags_devices_action_type_id === 1) {
            extras.push({ img: e.image });
          } else if (
            showDeviceImg &&
            (e.silobags_devices_action_type_id === 2 ||
              e.silobags_devices_action_type_id === 3)
          ) {
            extras.push({ img: e.image });
          }
        });
    });
  }
  return extras;
};

const downloadMerchandiseCertificate = async (values: any, userData: any) => {
  const profile =
    typeof userData.userProfile === "object"
      ? userData.userProfile
      : JSON.parse(userData.userProfile);
  const blob = await pdf(
    <SiloBagPdfComponent silobag={values} userProfile={profile} />
  ).toBlob();
  let tempLink = document.createElement("a");
  tempLink.href = window.URL.createObjectURL(blob);
  tempLink.setAttribute(
    "download",
    `CMC ${values.name} ${values.confection_date}.pdf`
  );
  tempLink.click();
};

const metricsColors = [
  "#9400D3",
  "#FF0000",
  "#4B0082",
  "#FFFF00",
  "#0000FF",
  "#00FF00",
  "#FF7F00",
];

const getFileName = (value: string) => {
  const filePathSplit = value.split("/");
  const fileName =
    filePathSplit &&
    filePathSplit.length > 0 &&
    filePathSplit[filePathSplit.length - 1];
  if (fileName) {
    return fileName
      .replace(/%20/g, " ")
      .replace(/%28/g, "(")
      .replace(/%29/g, ")");
  }
  return "-";
};

export {
  metricsColors,
  asyncLocalStorage,
  alertColor,
  stateSiloBagColorBackground,
  stateSiloBagColorText,
  stateSiloBagName,
  cloneWithoutReference,
  diffDates,
  findIndex,
  findCity,
  formatDate,
  formatDatetime,
  formatDatetimeToSave,
  formatPermission,
  generateAlertData,
  getAlertActionInfo,
  getExtraImgs,
  getFileName,
  getIconFromProduct,
  getNotificationData,
  getValueFromQueue,
  getTextColor,
  getMarkdownContent,
  getRenderDataForDevices,
  getRenderMeasurementDataForDevices,
  groupAlertsAndSort,
  convertSvgToDataImage,
  titleCase,
  applyFiltersToSiloBags,
  establishmentIcon,
  downloadMerchandiseCertificate,
};
