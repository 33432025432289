import { type } from "@testing-library/user-event/dist/type";
import * as types from "./actionTypes";

const initialState = {
  availableDevices: undefined,
  deviceToAttach: undefined,
  deviceActionType: undefined,
  silobagList: undefined,
  filteredSiloBags: undefined,
  countrysidesList: undefined,
  harvests: undefined,
  lots: undefined,
  products: undefined,
  species: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const SiloBagReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_ALL_AVAILABLE_DEVICE_ACTION:
    case types.GET_COUNTRYSIDES:
    case types.GET_HARVEST:
    case types.GET_LOT:
    case types.GET_PRODUCTS:
    case types.GET_SILOBAG:
    case types.UPDATE_SILOBAGS_FILTERED_LIST:
    case types.GET_SPECIES:
    case types.GET_DEVICE_TO_ATTACH_ACTION:
    case types.GET_DEVICE_ACTION_TYPE_ACTION:
      state = { ...state, loading: true };
      break;

    case types.GET_COUNTRYSIDES_SUCCESS:
      const { organization_id, response } = actionPayload;

      const countrysides = response.filter(
        (r: any) => r.companies.organization_id === organization_id
      );
      state = {
        ...state,
        filteredSiloBags: undefined,
        countrysidesList: countrysides,
        loading: false,
      };
      break;

    case types.GET_HARVEST_SUCCESS:
      state = { ...state, harvests: actionPayload, loading: false };
      break;

    case types.GET_LOT_SUCCESS:
      state = { ...state, lots: actionPayload, loading: false };
      break;

    case types.GET_PRODUCTS_SUCCESS:
      state = { ...state, products: actionPayload, loading: false };
      break;

    case types.GET_SPECIES_SUCCESS:
      state = { ...state, species: actionPayload, loading: false };
      break;

    case types.GET_SILOBAG_SUCCESS:
      const silobagsUID = actionPayload
        .filter((s: any) => s.lots.countrysides.companies)
        .map((val: any) => {
          const btoa = `${val.id}#%${val.created_at}`;
          return {
            ...val,
            uuid: window.btoa(btoa),
            lots_country_sides: val.lots.countrysides,
          };
        });
      state = {
        ...state,
        silobagList: silobagsUID,
        filteredSiloBags: silobagsUID,
        loading: false,
      };
      break;
    case types.UPDATE_SILOBAGS_FILTERED_LIST_SUCCESS:
      state = { ...state, filteredSiloBags: actionPayload, loading: false };
      break;

    case types.GET_ALL_AVAILABLE_DEVICE_SUCCESS:
      state = { ...state, availableDevices: actionPayload, loading: false };
      break;

    case types.GET_DEVICE_TO_ATTACH_SUCCESS:
      state = { ...state, deviceToAttach: actionPayload, loading: false };
      break;

    case types.GET_DEVICE_ACTION_TYPE_SUCCESS:
      state = { ...state, deviceActionType: actionPayload, loading: false };
      break;

    case types.GET_ALL_AVAILABLE_DEVICE_FAIL:
    case types.GET_COUNTRYSIDES_FAIL:
    case types.GET_HARVEST_FAIL:
    case types.GET_LOT_FAIL:
    case types.GET_PRODUCTS_FAIL:
    case types.GET_SPECIES_FAIL:
    case types.UPDATE_SILOBAGS_FILTERED_LIST_FAIL:
    case types.GET_SILOBAG_FAIL:
    case types.GET_DEVICE_TO_ATTACH_FAIL:
    case types.GET_DEVICE_ACTION_TYPE_FAIL:
      state = {
        ...state,
        error: {
          message: actionPayload,
        },
        loading: false,
      };
      break;
  }
  return state;
};

export default SiloBagReducer;
